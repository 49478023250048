import {
  GroceryListChangeKind,
  GroceryListInfo,
  GroceryListItemID,
  GroceryListProposalID,
} from "@phosphor/server";
import { deepEqual } from "@tanstack/react-router";

export function compactGroceryListChanges(
  changes: GroceryListChangeKind[],
  savedItems: { id: GroceryListItemID; title: string; price: number }[],
  initialGroceryList: GroceryListInfo,
): GroceryListChangeKind[] {
  const compactedChanges: GroceryListChangeKind[] = [];
  const lastChangeByKind: Record<string, GroceryListChangeKind> = {};
  const itemChanges: Record<string, GroceryListChangeKind> = {};

  // Helper function to check if an item exists in savedItems
  const isItemInSavedItems = (itemId: GroceryListItemID) =>
    savedItems.some((item) => item.id === itemId);

  for (const change of changes) {
    switch (change._tag) {
      case "UpdateDisplayName":
        if (change.newDisplayName !== initialGroceryList.displayName) {
          lastChangeByKind[change._tag] = change;
        } else {
          delete lastChangeByKind[change._tag];
        }
        break;
      case "UpdateDescription":
        if (change.newDescription !== initialGroceryList.description) {
          lastChangeByKind[change._tag] = change;
        } else {
          delete lastChangeByKind[change._tag];
        }
        break;
      case "UpdateColor":
        if (!deepEqual(change.newColor, initialGroceryList.color)) {
          lastChangeByKind[change._tag] = change;
        } else {
          delete lastChangeByKind[change._tag];
        }
        break;
      case "AddItem":
        if (isItemInSavedItems(change.itemId)) {
          // If the item already exists, this AddItem is unnecessary
          delete itemChanges[change.itemId];
        } else {
          itemChanges[change.itemId] = change;
        }
        break;
      case "RemoveItem":
        if (isItemInSavedItems(change.itemId)) {
          // If the item exists in savedItems, keep the RemoveItem change
          itemChanges[change.itemId] = change;
        } else {
          // If the item doesn't exist in savedItems, this RemoveItem is unnecessary
          delete itemChanges[change.itemId];
        }
        break;
      case "UpdateItemTitle":
        const savedItem = savedItems.find((item) => item.id === change.itemId);
        if (savedItem) {
          if (change.newTitle !== savedItem.title) {
            itemChanges[change.itemId] = change;
          } else {
            delete itemChanges[change.itemId];
          }
        } else if (itemChanges[change.itemId]?._tag === "AddItem") {
          // Merge UpdateItemTitle into existing AddItem
          itemChanges[change.itemId] = {
            ...itemChanges[change.itemId],
            // @ts-ignore much easier to read code like this
            title: change.newTitle,
          };
        }
        break;
      case "UpdateItemPrice":
        // Similar logic to UpdateItemTitle
        const savedItemForPrice = savedItems.find(
          (item) => item.id === change.itemId,
        );
        if (savedItemForPrice) {
          if (change.newPrice !== savedItemForPrice.price) {
            itemChanges[change.itemId] = change;
          } else {
            delete itemChanges[change.itemId];
          }
        } else if (itemChanges[change.itemId]?._tag === "AddItem") {
          // Merge UpdateItemPrice into existing AddItem
          itemChanges[change.itemId] = {
            ...itemChanges[change.itemId],
            // @ts-ignore much easier to read code like this
            price: change.newPrice,
          };
        }
        break;
    }
  }

  // Combine all changes
  return [
    ...compactedChanges,
    ...Object.values(lastChangeByKind),
    ...Object.values(itemChanges),
  ];
}

export const BaseChange = "BaseChange";
export type BaseChange = typeof BaseChange;

export const UnsavedChange = "UnsavedChange";
export type UnsavedChange = typeof UnsavedChange;

export type ChangeCameFrom = UnsavedChange | BaseChange | GroceryListProposalID;

export function getGroceryItemsFromChanges(
  changesets: GroceryListChangeKind[],
  proposal: ChangeCameFrom = BaseChange,
  _items: {
    id: GroceryListItemID;
    title: string;
    price: number;
    addedBy: ChangeCameFrom;
    removedBy: ChangeCameFrom | null;
    updatedBy: ChangeCameFrom | null;
    // Consider conflicts like this:
    // conflicts?: [ChangeCameFrom, { UpdatedTitle: string } | "REMOVED"][],
  }[] = [],
) {
  const items = structuredClone(_items);
  for (const change of changesets) {
    switch (change._tag) {
      case "AddItem":
        items.push({
          id: change.itemId,
          title: change.title,
          price: change.price,
          addedBy: proposal,
          removedBy: null,
          updatedBy: null,
        });
        break;
      case "RemoveItem":
        const index = items.findIndex((item) => item.id === change.itemId);
        if (index !== -1) {
          items[index].removedBy = proposal;
        }
        break;
      case "UpdateItemTitle":
        const itemToUpdateTitle = items.find(
          (item) => item.id === change.itemId,
        );
        if (itemToUpdateTitle) {
          itemToUpdateTitle.title = change.newTitle;
          itemToUpdateTitle.updatedBy = proposal;
        }
        break;
      case "UpdateItemPrice":
        const itemToUpdatePrice = items.find(
          (item) => item.id === change.itemId,
        );
        if (itemToUpdatePrice) {
          itemToUpdatePrice.price = change.newPrice;
          itemToUpdatePrice.updatedBy = proposal;
        }
        break;
    }
  }
  return items;
}
