import { S } from "@phosphor/prelude";
import {
  AppRequestFailures,
  OrgFolderID,
  OrgUserID,
  WorkspaceID,
  WorkspaceRole,
} from "../common";

export class WorkspaceCreated extends S.Class<WorkspaceCreated>(
  "WorkspaceCreated",
)({
  workspace_id: WorkspaceID,
  workspace_display_name: S.String,
}) {}

export class WorkspaceWithUsers extends S.Class<WorkspaceWithUsers>(
  "WorkspaceWithUser",
)({
  workspaceId: WorkspaceID,
  workspaceDisplayName: S.String,
  users: S.Array(
    S.Struct({
      id: OrgUserID,
      role: WorkspaceRole,
    }),
  ),
}) {}

export class WorkspaceWithOwner extends S.Class<WorkspaceWithOwner>(
  "WorkspaceWithOwner",
)({
  workspaceId: WorkspaceID,
  workspaceDisplayName: S.String,
  workspaceOwner: S.String,
}) {}

export type WorkspaceCreatedArray = WorkspaceCreated[];

export class CreateWorkspace extends S.TaggedRequest<CreateWorkspace>()(
  "CreateWorkspace",
  {
    payload: { folder: OrgFolderID.pipe(S.optional), displayName: S.String },
    success: WorkspaceCreated,
    failure: AppRequestFailures,
  },
) {}

/**
 * Retrieves workspaces for a given folder or the organization's default folder.
 *
 * If the folder is not specified:
 * 1. The organization's default folder will be used.
 * 2. If there's no default folder, a new default folder will be created in the database.
 */
export class GetWorkspaces extends S.TaggedRequest<GetWorkspaces>()(
  "GetWorkspaces",
  {
    payload: { folder: OrgFolderID.pipe(S.optional) },
    success: S.Array(WorkspaceWithUsers),
    failure: AppRequestFailures,
  },
) {}

export class GetSharedWorkspaces extends S.TaggedRequest<GetSharedWorkspaces>()(
  "GetSharedWorkspaces",
  {
    payload: { user: OrgUserID },
    success: S.Array(WorkspaceWithUsers),
    failure: AppRequestFailures,
  },
) {}
