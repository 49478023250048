import { S } from "@phosphor/prelude";
import {
  AppRequestFailures,
  JWTAccessToken,
  OrgID,
  OrgInfo,
  OrgURLKey,
  OrgUserInfo,
} from "../common";

// create org

/** See {@link CreateOrganization} */
export class OrgCreated extends S.Class<OrgCreated>("OrgCreated")({
  orgInfo: OrgInfo,
  userInfo: OrgUserInfo,
  userAccessToken: JWTAccessToken,
}) {}

/**
 * Create a new, empty organization.
 * With a default space.
 */
export class CreateOrganization extends S.TaggedRequest<CreateOrganization>()(
  "CreateOrganization",
  {
    payload: { name: S.String, urlKey: S.String, creatorHint: S.String },
    success: OrgCreated,
    failure: AppRequestFailures,
  },
) {}

/** See {@link GetOrgInfo} */
export class OrgInfoRetrieved extends S.Class<OrgInfoRetrieved>(
  "OrgInfoRetrieved",
)({
  orgInfo: OrgInfo,
}) {}

/** Get organization information */
export class GetOrgInfo extends S.TaggedRequest<GetOrgInfo>()("GetOrgInfo", {
  payload: { orgID: OrgID },
  success: OrgInfoRetrieved,
  failure: AppRequestFailures,
}) {}

/** See {@link UpdateOrgInfo} */
export class OrgInfoUpdated extends S.Class<OrgInfoUpdated>("OrgInfoUpdated")({
  orgInfo: OrgInfo,
}) {}

/** Update organization information */
export class UpdateOrgInfo extends S.TaggedRequest<UpdateOrgInfo>()(
  "UpdateOrgInfo",
  {
    payload: {
      orgID: OrgID,
      displayName: S.String.pipe(S.optional),
      urlKey: OrgURLKey.pipe(S.optional),
      logoUrl: S.String.pipe(S.optional),
    },
    success: OrgInfoUpdated,
    failure: AppRequestFailures,
  },
) {}
