import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute(
  "/$org/_layout/workspace/$workspace/_layout/databook",
)({
  component: WorkspaceDatabook,
});

function WorkspaceDatabook() {
  const [searchTerm, setSearchTerm] = useState("");
  const mockData = [
    { id: 1, name: "Revenue", type: "Number", value: "$1,000,000" },
    { id: 2, name: "Expenses", type: "Number", value: "$750,000" },
    { id: 3, name: "Profit Margin", type: "Percentage", value: "25%" },
    { id: 4, name: "Customer Count", type: "Integer", value: "5,000" },
    {
      id: 5,
      name: "Product Categories",
      type: "List",
      value: "Electronics, Clothing, Books",
    },
  ];

  const filteredData = mockData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div $="p-6 bg-white h-[100vh]">
      <h2 $="text-2xl font-semibold mb-6">Data Book</h2>
      <div $="mb-4">
        <Input
          $="w-full max-w-md"
          type="text"
          placeholder="Search data..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div $="overflow-x-auto">
        <table $="min-w-full bg-white border border-gray-300">
          <thead $>
            <tr $="bg-gray-100">
              <th $="py-2 px-4 border-b text-left">Name</th>
              <th $="py-2 px-4 border-b text-left">Type</th>
              <th $="py-2 px-4 border-b text-left">Value</th>
              <th $="py-2 px-4 border-b text-left">Actions</th>
            </tr>
          </thead>
          <tbody $>
            {filteredData.map((item) => (
              <tr key={item.id} $="hover:bg-gray-50">
                <td $="py-2 px-4 border-b">{item.name}</td>
                <td $="py-2 px-4 border-b">{item.type}</td>
                <td $="py-2 px-4 border-b">{item.value}</td>
                <td $="py-2 px-4 border-b">
                  <button $="text-blue-500 hover:text-blue-700 mr-2">
                    <Ta.IconEdit $="w-5 h-5" />
                  </button>
                  <button $="text-red-500 hover:text-red-700">
                    <Ta.IconTrash $="w-5 h-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
