import { type RPCClient, useRpcClient } from "@/hooks/use-rpc-hooks";
import {
  BaseChange,
  UnsavedChange,
  compactGroceryListChanges,
  getGroceryItemsFromChanges,
} from "@/utils/groceryListUtils";
import { debounce } from "@phosphor/prelude";
import {
  Color,
  GroceryListChangeKind,
  GroceryListID,
  GroceryListInfo,
  GroceryListItemID,
  GroceryListProposalAPI,
  GroceryListProposalID,
  OrgID,
} from "@phosphor/server";
import { deepEqual } from "@tanstack/react-router";
import { isNotNull } from "effect/Predicate";
import React, { createContext, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { type Snapshot, proxy, useSnapshot } from "valtio";
import { subscribeKey } from "valtio/utils";

export interface GroceryListItemDisplay {
  id: GroceryListItemID;
  title: string;
  price: number;
  isAdded: boolean;
  isUpdated: boolean;
  isRemoved: boolean;
  canUndoRemove: boolean;
  updateTitle(title: string): void;
  undoRemove(): void;
  remove(): void;
}

interface GroceryListInternalState {
  /**
   * The current saved state of the grocery list.
   */
  groceryList: GroceryListInfo;
  /**
   * The changes that have been made but not saved to the proposal or server.
   * These are staged for a proposal comment.
   */
  unsavedChanges: GroceryListChangeKind[];
  /**
   * The proposals that are currently selected for comparison above this grocery list.
   */
  selectedProposals: GroceryListProposalID[];
  originalItems: Record<GroceryListItemID, { title: string; price: number }>;
}

type GroceryListItemListDisplay = {
  items: GroceryListItemDisplay[];
  newItemText: string;
  setNewItemText: (text: string) => void;
  readonly addItem: () => void;
};

interface GroceryListDisplayState {
  readonly id: GroceryListID;
  readonly displayName: ChangeTracked<string>;
  readonly description: ChangeTracked<string>;
  readonly color: ChangeTracked<Color>;
  readonly itemList: Snapshot<GroceryListItemListDisplay>;
}

type ChangeTracked<T> = {
  originalValue: T;
  currentValue: T;
  isModified: boolean;
  reset: () => void;
  set: (value: T) => void;
};

/** Change tracked, and the change is isolated from other change tracked values. */
function createIsolatedChangeTracked<T>(
  initialValue: T,
  changed: (value: T) => void,
): ChangeTracked<T> {
  const state = proxy<ChangeTracked<T>>({
    originalValue: initialValue,
    currentValue: initialValue,
    isModified: false,
    reset: () => {
      state.currentValue = state.originalValue;
      state.isModified = false;
    },
    set: (value: T) => {
      state.currentValue = value;
      state.isModified = !deepEqual(state.currentValue, state.originalValue);
      changed(value);
    },
  });
  return state;
}

interface ProposalInfo {
  id: GroceryListProposalID;
  changes: GroceryListChangeKind[];
}

interface GroceryListDataState {
  proposals: Record<GroceryListProposalID, ProposalInfo>;
}

const createGroceryListStore = (
  client: RPCClient,
  savedGroceryList: GroceryListInfo,
) => {
  const savedItems = getGroceryItemsFromChanges(
    savedGroceryList.changesets.flatMap((c) => c.changes.map((c) => c.change)),
  );
  const internal = proxy<GroceryListInternalState>({
    groceryList: savedGroceryList,
    unsavedChanges: [],
    selectedProposals: [],
    originalItems: Object.fromEntries(
      savedItems.map((item) => [
        item.id,
        { title: item.title, price: item.price },
      ]),
    ),
  });

  const dataState = proxy<GroceryListDataState>({
    proposals: {},
  });

  const itemList = proxy<GroceryListItemListDisplay>({
    items: [],
    newItemText: "",
    setNewItemText: (text: string) => {
      itemList.newItemText = text;
    },
    addItem: () => {
      if (itemList.newItemText.trim()) {
        const newItemId = GroceryListItemID.make(uuidv4());
        addChange({
          _tag: "AddItem",
          itemId: newItemId,
          title: itemList.newItemText.trim(),
          price: 0,
        });
        itemList.newItemText = "";
      }
    },
  });

  const addChange = (change: GroceryListChangeKind) => {
    internal.unsavedChanges = compactGroceryListChanges(
      [...internal.unsavedChanges, change],
      savedItems,
      internal.groceryList,
    );
  };

  const display: GroceryListDisplayState = {
    id: savedGroceryList.id,
    displayName: createIsolatedChangeTracked(
      savedGroceryList.displayName,
      (value) =>
        addChange({ _tag: "UpdateDisplayName", newDisplayName: value }),
    ),
    description: createIsolatedChangeTracked(
      savedGroceryList.description,
      (value) =>
        addChange({ _tag: "UpdateDescription", newDescription: value }),
    ),
    color: createIsolatedChangeTracked(savedGroceryList.color, (value) =>
      addChange({
        _tag: "UpdateColor",
        newColor: value,
      }),
    ),
    itemList,
  };

  const fetchProposalChanges = async (proposalId: GroceryListProposalID) => {
    try {
      const response = await client(
        new GroceryListProposalAPI.GetProposal({
          proposalId,
        }),
      );
      console.info(`Fetched proposal ${proposalId}:`, response);
      dataState.proposals[proposalId] = {
        id: proposalId,
        changes: response.comments.flatMap((comment) =>
          comment.content.flatMap((content) =>
            content._tag === "Changes" ? content.changes : [],
          ),
        ),
      };
      // Recompute now that we have the proposal changes loaded
      recomputeItems();
    } catch (error) {
      console.error(
        `Error fetching changes for proposal ${proposalId}:`,
        error,
      );
    }
  };

  const recomputeItems = debounce(0, () => {
    let items = savedItems;
    // first, apply the selected proposals
    for (const proposalId of internal.selectedProposals) {
      const proposal = dataState.proposals[proposalId];
      if (proposal) {
        items = getGroceryItemsFromChanges(
          proposal.changes,
          proposal.id,
          items,
        );
      } else {
        console.error(`Proposal ${proposalId} not found`);
      }
    }
    // then, apply the unsaved changes
    items = getGroceryItemsFromChanges(
      internal.unsavedChanges,
      UnsavedChange,
      items,
    );
    itemList.items = items
      .map((info): null | GroceryListItemDisplay => {
        if (info.removedBy === BaseChange) {
          return null;
        }
        return {
          id: info.id,
          title: info.title,
          price: info.price,
          isAdded: info.addedBy != BaseChange,
          isUpdated: info.updatedBy != null && info.updatedBy !== BaseChange,
          isRemoved: info.removedBy != null,
          updateTitle(title: string) {
            addChange({
              _tag: "UpdateItemTitle",
              itemId: info.id,
              newTitle: title,
            });
          },
          remove() {
            addChange({ _tag: "RemoveItem", itemId: info.id });
          },
          canUndoRemove: info.removedBy === UnsavedChange,
          undoRemove() {
            internal.unsavedChanges = internal.unsavedChanges.filter(
              (change) =>
                !(change._tag === "RemoveItem" && change.itemId === info.id),
            );
          },
        };
      })
      .filter(isNotNull);
  });

  subscribeKey(internal, "unsavedChanges", recomputeItems);
  subscribeKey(internal, "groceryList", recomputeItems);
  subscribeKey(internal, "selectedProposals", (selectedProposals) => {
    console.info(`Selected proposals changed to:`, selectedProposals);
    for (const proposalId of selectedProposals) {
      fetchProposalChanges(proposalId);
    }
    recomputeItems();
  });
  subscribeKey(dataState, "proposals", recomputeItems);
  recomputeItems();

  const clearChanges = () => {
    internal.unsavedChanges = [];
    display.displayName.reset();
    display.description.reset();
    display.color.reset();
  };

  const toggleProposal = (proposalId: GroceryListProposalID) => {
    if (internal.selectedProposals.includes(proposalId)) {
      internal.selectedProposals = internal.selectedProposals.filter(
        (id) => id !== proposalId,
      );
    } else {
      fetchProposalChanges(proposalId);
      internal.selectedProposals.push(proposalId);
    }
    recomputeItems();
  };

  return {
    internal,
    display,
    dataState,
    addChange,
    clearChanges,
    toggleProposal,
  };
};

const GroceryListContext = createContext<ReturnType<
  typeof createGroceryListStore
> | null>(null);

export const GroceryListProvider: React.FC<{
  orgId: OrgID;
  initialGroceryList: GroceryListInfo;
  children: React.ReactNode;
}> = React.memo(({ orgId, initialGroceryList, children }) => {
  const client = useRpcClient(orgId);
  const store = React.useMemo(
    () => createGroceryListStore(client, initialGroceryList),
    [initialGroceryList, client],
  );

  return (
    <GroceryListContext.Provider value={store}>
      {children}
    </GroceryListContext.Provider>
  );
});

export const useGroceryList = () => {
  const store = useContext(GroceryListContext);
  if (!store) {
    throw new Error("useGroceryList must be used within a GroceryListProvider");
  }

  const { internal, display, dataState, clearChanges, toggleProposal } = store;

  const internalState = useSnapshot(internal);
  const proposalsData = useSnapshot(dataState);

  const displayState: GroceryListDisplayState = {
    id: display.id,
    displayName: useSnapshot(display.displayName),
    description: useSnapshot(display.description),
    color: useSnapshot(display.color),
    itemList: useSnapshot(display.itemList),
  };

  return {
    groceryList: displayState,
    get selectedProposals() {
      return internalState.selectedProposals;
    },
    history: {
      get changesets() {
        return internalState.groceryList.changesets;
      },
    },
    proposalsData: proposalsData.proposals,
    toggleProposal,
    clearChanges,
    dev: internalState,
    currentState: {
      items: displayState.itemList,
      displayName: displayState.displayName,
      description: displayState.description,
      color: displayState.color,
    },
    originalItems: internalState.originalItems,
  };
};
