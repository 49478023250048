import { S } from "@phosphor/prelude";
import {
  AppRequestFailures,
  Color,
  GroceryListChangeKind,
  GroceryListChangeset,
  GroceryListID,
  GroceryListInfo,
  OrgID,
} from "../common";

export class GroceryListCreated extends S.Class<GroceryListCreated>(
  "GroceryListCreated",
)({
  groceryList: GroceryListInfo,
}) {}

export class CreateGroceryList extends S.TaggedRequest<CreateGroceryList>()(
  "CreateGroceryList",
  {
    payload: {
      orgID: OrgID,
      displayName: S.String,
      description: S.String,
      color: Color,
    },
    success: GroceryListCreated,
    failure: AppRequestFailures,
  },
) {}

export class GroceryListsRetrieved extends S.Class<GroceryListsRetrieved>(
  "GroceryListsRetrieved",
)({
  groceryLists: S.Array(GroceryListInfo),
}) {}

export class ListGroceryLists extends S.TaggedRequest<ListGroceryLists>()(
  "ListGroceryLists",
  {
    payload: { orgID: OrgID },
    success: GroceryListsRetrieved,
    failure: AppRequestFailures,
  },
) {}

export class GroceryListRetrieved extends S.Class<GroceryListRetrieved>(
  "GroceryListRetrieved",
)({
  groceryList: S.Struct({
    ...GroceryListInfo.fields,
    changesets: S.Array(GroceryListChangeset),
  }),
}) {}

export class GetGroceryList extends S.TaggedRequest<GetGroceryList>()(
  "GetGroceryList",
  {
    payload: {
      groceryListID: GroceryListID,
    },
    success: GroceryListRetrieved,
    failure: AppRequestFailures,
  },
) {}

export class UpdateGroceryList extends S.TaggedRequest<UpdateGroceryList>()(
  "UpdateGroceryList",
  {
    payload: {
      groceryListID: GroceryListID,
      displayName: S.String.pipe(S.optional),
      description: S.String.pipe(S.optional),
      color: Color.pipe(S.optional),
    },
    success: GroceryListRetrieved,
    failure: AppRequestFailures,
  },
) {}

export class DeleteGroceryList extends S.TaggedRequest<DeleteGroceryList>()(
  "DeleteGroceryList",
  {
    payload: {
      groceryListID: GroceryListID,
    },
    success: S.Struct({ deleted: S.Boolean }),
    failure: AppRequestFailures,
  },
) {}

export class SaveGroceryListChanges extends S.TaggedRequest<SaveGroceryListChanges>()(
  "SaveGroceryListChanges",
  {
    payload: {
      groceryListID: GroceryListID,
      changes: S.Array(GroceryListChangeKind),
    },
    success: GroceryListRetrieved,
    failure: AppRequestFailures,
  },
) {}
