import { Ta } from "@/components/ui/icons";
import logo from "../../public/Logo.svg?url";
import avatar from "../../public/avatar.svg?url";
import oliver from "../../public/oliver.jpeg?url";
import shimmer from "../../public/shimmer.svg?url";

export const Shimmer = () => {
  return (
    <div $=" bg-background w-100 mx-auto shadow-md rounded-lg p-6 mt-4">
      <div $="flex justify-between border-b-2 border-[#D4D4D8] pb-4 mb-4">
        <div $="flex">
          <img $ src={logo} />
          <img $ src={shimmer} />
        </div>
        <Ta.IconWindowMaximize $="w-[20px] h-[20px] ml-1" color={"#7487AA"} />
      </div>

      <div $="flex items-center mb-4">
        <img src={oliver} alt="Profile" $="w-10 h-10 rounded-full mr-4" />
        <h1 $="text-small font-medium bg-[#F4F4F5] p-[8px] rounded-md ">
          Create a walk of changes between the closing model, and scenarios for
          new panels and interest rate
        </h1>
      </div>
      <div $="flex">
        <img src={avatar} alt="Profile" $="w-10 h-10 mr-4" />
        <div $="bg-background p-4 rounded-lg border border-[#D4D4D8] w-[100%]">
          <h2 $="text-lg font-medium mb-4">Here's an updated analysis</h2>
          <div $="grid grid-cols-3 gap-4">
            <div $="bg-background p-4 rounded-lg shadow">
              <h3 $="text-sm font-medium mb-2">
                Closing model on acquisition date
              </h3>
              <ul $="text-sm">
                <li $>Pre Tax IRR: 7.1%</li>
                <li $>Tax Equity IRR: 6.2%</li>
                <li $>Total Investment: 8.3%</li>
                <li $>Developer Profit: 10.5%</li>
              </ul>
            </div>
            <div $="bg-background p-4 rounded-lg shadow">
              <h3 $="text-sm font-medium mb-2">Base case</h3>
              <ul $="text-sm">
                <li $>Pre Tax IRR: 7.3%</li>
                <li $>Tax Equity IRR: 6.5%</li>
                <li $>Total Investment: 8.6%</li>
                <li $>Developer Profit: 10.8%</li>
              </ul>
            </div>
            <div $="bg-background p-4 rounded-lg shadow">
              <h3 $="text-sm font-medium mb-2">Scenario: New Solar Panels</h3>
              <ul $="text-sm">
                <li $>Assumes installed cost reduction</li>
                <li $>MW Cost: 1.5</li>
                <li $>WKP (P50): 8.0</li>
                <li $>Degradation: 1.0%</li>
                <li $>DSCR (Avg): 1.47</li>
              </ul>
            </div>
            <div $="bg-background p-4 rounded-lg shadow col-span-3">
              <h3 $="text-sm font-medium mb-2">
                Sensitivity: Interest Rate (5% Base Metric)
              </h3>
              <ul $="text-sm">
                <li $>Changed S&EA's Bids: 5.0%</li>
                <li $>Sensitivity on: IRR, Developer Profit</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div $="w-100 mx-auto mt-6  flex gap-4">
        <input
          type="text"
          $="w-[80%] p-2 border border-gray-300 rounded-lg bg-background "
          placeholder="Follow up with another prompt"
        />
        <div $="w-[20%] h-[40px] border border-[#D4D4D8] border-dotted flex flex-col justify-center items-center	">
          <div $="flex">
            <Ta.IconFileUpload $="w-[12px] h-[12px]" color="#7487AA" />
            <Ta.IconPhotoPlus $="w-[12px] h-[12px]" color="#7487AA" />
          </div>
          <p $="font-medium text-[8px] text-[#7487AA]">Attach or drop Files</p>
        </div>
      </div>
    </div>
  );
};
