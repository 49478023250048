import markdownit from "markdown-it";
import { defineBasicExtension } from "prosekit/basic";
import { MarkdownParser, MarkdownSerializer } from "prosemirror-markdown";
import { Schema } from "prosemirror-model";

// Define a schema that includes basic nodes and marks
const schema = new Schema({
  nodes: {
    doc: {
      content: "block+",
    },
    paragraph: {
      group: "block",
      content: "inline*",
      parseDOM: [{ tag: "p" }],
      toDOM() {
        return ["p", 0];
      },
    },
    text: {
      group: "inline",
    },
    hardBreak: {
      inline: true,
      group: "inline",
      selectable: false,
      parseDOM: [{ tag: "br" }],
      toDOM() {
        return ["br"];
      },
    },
  },
  marks: {
    bold: {
      parseDOM: [
        { tag: "strong" },
        { tag: "b" },
        {
          style: "font-weight",
          getAttrs: (value) =>
            /^(bold(er)?|[5-9]\d{2,})$/.test(value as string) && null,
        },
      ],
      toDOM() {
        return ["strong", 0];
      },
    },
    italic: {
      parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
      toDOM() {
        return ["em", 0];
      },
    },
  },
});

// Create markdown-it instance
const md = markdownit("commonmark");

// Create the markdown parser
const markdownParser = new MarkdownParser(schema, md, {
  blockquote: { block: "paragraph" },
  paragraph: { block: "paragraph" },
  list_item: { block: "paragraph" },
  bullet_list: { block: "paragraph" },
  ordered_list: { block: "paragraph" },
  heading: { block: "paragraph" },
  code_block: { block: "paragraph" },
  fence: { block: "paragraph" },
  hr: { node: "hardBreak" },
  image: { node: "paragraph" },
  hardbreak: { node: "hardBreak" },
  softbreak: { node: "hardBreak" },
  text: { node: "text" },
  strong: { mark: "bold" },
  em: { mark: "italic" },
  link: { mark: "link" },
  code_inline: { mark: "code" },
});

// Create the markdown serializer
const markdownSerializer = new MarkdownSerializer(
  {
    paragraph: (state, node) => {
      state.renderInline(node);
      state.closeBlock(node);
    },
    text: (state, node) => {
      state.text(node.text || "");
    },
    hardBreak: (state) => {
      state.write("\n");
    },
  },
  {
    bold: {
      open: "**",
      close: "**",
      mixable: true,
      expelEnclosingWhitespace: true,
    },
    italic: {
      open: "*",
      close: "*",
      mixable: true,
      expelEnclosingWhitespace: true,
    },
  },
);

// Create the extension
export const extension = defineBasicExtension();

export { schema, markdownParser, markdownSerializer };
