import { useGroceryList } from "@/contexts/GroceryListContext";
import React from "react";

export const GroceryListDevUI: React.FC = () => {
  const { dev } = useGroceryList();

  return (
    <div $="p-4 pt-3 my-2 bg-gray-100 rounded-md h-full overflow-auto">
      <h3 $="text-lg font-semibold mb-1">Dev UI</h3>
      <pre $="text-sm/4 overflow-x-auto whitespace-pre-wrap">
        Unsaved Changes: {JSON.stringify(dev.unsavedChanges, null, 2)}
      </pre>
      <pre $="text-sm/4 overflow-x-auto whitespace-pre-wrap">
        Selected Proposals: {JSON.stringify(dev.selectedProposals, null, 2)}
      </pre>
    </div>
  );
};
