import { UserNameChip } from "@/components/UserNameChip";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Ta } from "@/components/ui/icons";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useGroceryList } from "@/contexts/GroceryListContext";
import { GroceryListChangeset } from "@phosphor/server";
import React from "react";
import { GLProposalChip } from "./GLProposalChip";
import { RenderChangeIcon, formatChange } from "./GroceryListChangeUtils";

interface GroceryListHistoryListProps {}

const ChangesetHeader: React.FC<{
  changeset: GroceryListChangeset;
}> = ({ changeset }) => {
  const authoredBy = changeset.authoredBy ?? changeset.mergedBy;
  const mergedBy = changeset.mergedBy ?? changeset.authoredBy;
  const isSameUser = authoredBy === mergedBy;

  const showOnlyAuthoredBy = authoredBy && (mergedBy ? isSameUser : false);

  return (
    <div $="flex items-center justify-between mb-2 text-sm">
      <div $="flex items-center space-x-2">
        {showOnlyAuthoredBy ? (
          <>
            <UserNameChip userId={authoredBy} />
            <span $="text-gray-500">authored and merged</span>
          </>
        ) : (
          authoredBy &&
          mergedBy && (
            <>
              <UserNameChip userId={authoredBy} />
              <Ta.IconArrowNarrowRight $="w-4 h-4 text-gray-400" />
              <UserNameChip userId={mergedBy} />
            </>
          )
        )}
        {changeset.proposalId && (
          <>
            <span $="text-gray-500">from</span>
            <GLProposalChip proposalId={changeset.proposalId} />
          </>
        )}
      </div>
      <span $="text-gray-500">
        {new Date(changeset.createdAt).toLocaleString()}
      </span>
    </div>
  );
};

export const GroceryListHistoryList: React.FC<
  GroceryListHistoryListProps
> = ({}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { currentState, originalItems, history } = useGroceryList();

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger $ asChild>
        <Button $ variant="outline" size="sm">
          <Ta.IconHistory $="w-4 h-4 mr-2" />
          View History
        </Button>
      </DialogTrigger>
      <DialogContent $="max-w-2xl max-h-[80vh]">
        <DialogHeader $>
          <DialogTitle $>Grocery List History</DialogTitle>
        </DialogHeader>
        <ScrollArea $="h-[60vh]">
          <div $="space-y-6">
            {history.changesets.map((changeset) => (
              <div
                key={changeset.id}
                $="border-b border-gray-200 dark:border-gray-700 pb-4 last:border-b-0"
              >
                <ChangesetHeader changeset={changeset} />
                <ul $="space-y-2 mt-2">
                  {changeset.changes.map((change, index) => (
                    <li key={index} $="flex items-start">
                      <RenderChangeIcon change={change.change} />
                      <span $="ml-2 text-sm">
                        {formatChange(
                          change.change,
                          currentState,
                          originalItems,
                        )}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
