// @ts-check
const d = self.document.documentElement;
// While we are in the MVP phase, we want to disable default dark mode
// In the future, we can set up css variables properly.
const MVP_DISABLE_DARK_MODE = true;
try {
	/** @type {any} */
	let originalSetItem;
	null === localStorage.getItem("ApplicationStore") &&
		((originalSetItem = Storage.prototype.setItem),
		d.classList.add("logged-out"),
		// if we set the "ApplicationStore" key, remove the logged-out class
		/** @param {any[]} args */
		(Storage.prototype.setItem = function (...args) {
			originalSetItem.apply(this, args),
				"ApplicationStore" === args[0] &&
					(d.classList.remove("logged-out"),
					(Storage.prototype.setItem = originalSetItem));
		}));
} catch (e) {}
try {
	// dark mode management
	d.lang = navigator.language;
	const m = window.matchMedia("(prefers-color-scheme: dark)"),
		e = localStorage.getItem("darkMode"),
		t = !MVP_DISABLE_DARK_MODE && m.matches,
		o =
			(("true" === e || (null == e && t)) &&
				d.classList.add("dark"),
			localStorage.getItem("themeSidebarColor"));
	o && d.style.setProperty("--bg-color", o);
} catch (e) {
	console.error(e)
}

d.classList.add("loading");
setTimeout(() => {
	d.classList.add("loaded");
	requestAnimationFrame(() => {
		d.classList.remove("loading");
	});
}, 300);

import { posthog } from "posthog-js"
const { releaseTag, relatedEditorUIPosthog, relatedSentry } = BUILD_DEPLOY_INFO;
const { posthogApiKey } = BUILD_EDITOR_ENV;
if (relatedEditorUIPosthog && posthogApiKey) {
	const {host} = relatedEditorUIPosthog;
	// https://posthog.com/docs/libraries/js
	posthog.init(
		posthogApiKey,
		{
			api_host: host,
			person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
			loaded(ph) {
				ph.register_for_session({ releaseTag })
				window.ph = ph;
				if (relatedSentry) {
					const url = new URL(relatedSentry.editorWebui.projectBaseURL); 
					url.searchParams.set("project", relatedSentry.editorWebui.projectID); 
					url.searchParams.set("environment", relatedSentry.environment); 
					console.warn("TODO: Link Sentry release tag to PostHog release tag")
					// relatedEditorUISentry.
					ph.register_for_session({
						sentryURL: url.toString(),
					})
				}
			}
		},
	);
} else {
	console.warn("BUILD_DEPLOY_INFO or BUILD_EDITOR_ENV is missing to setup posthog")
}