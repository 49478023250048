import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";

interface AddImageModalProps {
  isOpen: boolean;
  initialURL: string;
  onChosen: (newURL: string) => void;
  onDismiss: () => void;
  modalTitle: string;
}

export function AddImageModal({
  isOpen,
  initialURL,
  onChosen,
  onDismiss,
  modalTitle,
}: AddImageModalProps) {
  const [imageURL, setImageURL] = useState(initialURL);

  useEffect(() => {
    setImageURL(initialURL);
  }, [initialURL]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onChosen(imageURL);
  };

  const handleRemoveImage = () => {
    setImageURL("");
    onChosen("");
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onDismiss()}>
      <DialogContent $="sm:max-w-[425px]">
        <DialogHeader $>
          <DialogTitle $>{modalTitle}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} $="space-y-4">
          <Input
            $
            type="url"
            placeholder="Enter image URL"
            value={imageURL}
            onChange={(e) => setImageURL(e.target.value)}
          />
          {imageURL && (
            <div $="flex justify-center">
              <img
                $="max-w-full max-h-40 object-contain"
                src={imageURL}
                alt="Preview"
              />
            </div>
          )}
          <div $="flex justify-end space-x-2">
            <Button $ type="button" variant="outline" onClick={onDismiss}>
              Cancel
            </Button>
            {initialURL !== "" && (
              <Button
                $
                type="button"
                variant="destructive"
                onClick={handleRemoveImage}
              >
                Remove Image
              </Button>
            )}
            <Button $ type="submit">
              Choose
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
