import { unindentTemplate } from "./template-strings.js";
import { isTruthy } from "./truthy-and-when.js";

export type SafeHTML = { HTML: string };
/** safely escape html */

export function html(
  template: TemplateStringsArray,
  ...args: (string | number | SafeHTML | undefined)[]
): SafeHTML {
  return {
    HTML: String.raw(
      unindentTemplate(template),
      ...args.map((a) =>
        a
          ? typeof a === "object"
            ? a.HTML
            : typeof a === "number"
              ? String(a)
              : a
                  .replace(/&/g, "&amp;")
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;")
                  .replace(/"/g, "&quot;")
          : "",
      ),
    ),
  };
}

export function basicHTMLPage(options: {
  title: string;
  bodyHTML: ({ HTML: string } | undefined)[];
  color?: { hue: number; chroma: number } | undefined;
}): SafeHTML {
  const color = options.color ?? { hue: 295.77, chroma: 0.1 };
  const anchor = `${color.chroma * 2} ${color.hue}`;
  const style = `
html { background: oklch(0.9 ${color.chroma * 1.1} ${color.hue}); color: oklch(0.4 ${color.chroma * 0.7} ${color.hue}); } 
body { margin: 0 auto; padding: 4rem; max-width: 600px; font-family: system-ui, sans-serif; }
a { cursor: pointer; color: oklch(0.4 ${anchor}); background: oklch(0.9 ${anchor} / 0.1); text-decoration: none; transition: all 0.2s ease-in; }
a:hover { color: oklch(0.1 ${anchor}); background: oklch(0.9 ${anchor} / 0.9); }
`;
  return {
    HTML: `<!DOCTYPE html>\n<html lang="en">\n<head><meta charset="UTF-8"><title>${options.title}</title><style>${style}</style></head>\n<body>\n<h1>${options.title}</h1>\n${options.bodyHTML
      .filter(isTruthy)
      .map((a) => a.HTML)
      .join("\n")}\n</body>\n</html>`,
  };
}
