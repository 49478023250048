import { S } from "@phosphor/prelude";
import {
  AppRequestFailures,
  CommentTsID,
  CommentVisibility,
  GroceryListCommentContentKind,
  GroceryListID,
  GroceryListProposalID,
  OrgUserID,
  ProposalStatus,
} from "../common";

// Proposal creation
export class ProposalCreated extends S.Class<ProposalCreated>(
  "ProposalCreated",
)({
  proposalId: GroceryListProposalID,
  groceryListId: GroceryListID,
  title: S.String,
  description: S.String,
  status: ProposalStatus,
  createdByUserId: OrgUserID,
  createdAt: S.Date,
}) {}

export class CreateProposal extends S.TaggedRequest<CreateProposal>()(
  "CreateProposal",
  {
    payload: {
      groceryListId: GroceryListID,
      title: S.String,
      description: S.String,
      initialContent: S.Array(GroceryListCommentContentKind),
    },
    success: ProposalCreated,
    failure: AppRequestFailures,
  },
) {}

// Proposal retrieval
export class ProposalInfo extends S.Class<ProposalInfo>("ProposalInfo")({
  id: GroceryListProposalID,
  groceryListId: GroceryListID,
  title: S.String,
  description: S.String,
  status: ProposalStatus,
  createdBy: OrgUserID,
  createdAt: S.Date,
  updatedAt: S.Date,
}) {}

export class ProposalsRetrieved extends S.Class<ProposalsRetrieved>(
  "ProposalsRetrieved",
)({
  proposals: S.Array(ProposalInfo),
}) {}

export class ListProposals extends S.TaggedRequest<ListProposals>()(
  "ListProposals",
  {
    payload: {
      groceryListId: GroceryListID,
      statuses: S.Array(ProposalStatus).pipe(S.optional),
    },
    success: ProposalsRetrieved,
    failure: AppRequestFailures,
  },
) {}

// Comment retrieval
export class CommentInfo extends S.Class<CommentInfo>("CommentInfo")({
  proposalId: GroceryListProposalID,
  ts: CommentTsID,
  authorUserId: OrgUserID,
  content: S.Array(GroceryListCommentContentKind),
  topic: S.String.pipe(S.optional),
  replyToTs: CommentTsID.pipe(S.optional),
  visibility: CommentVisibility,
  version: S.Number,
  updatedAt: S.Date,
}) {}

// Single proposal retrieval
export class ProposalWithComments extends S.Class<ProposalWithComments>(
  "ProposalWithComments",
)({
  proposal: ProposalInfo,
  comments: S.Array(CommentInfo),
}) {}

export class GetProposal extends S.TaggedRequest<GetProposal>()("GetProposal", {
  payload: {
    proposalId: GroceryListProposalID,
  },
  success: ProposalWithComments,
  failure: AppRequestFailures,
}) {}

// Proposal update
export class UpdateProposal extends S.TaggedRequest<UpdateProposal>()(
  "UpdateProposal",
  {
    payload: {
      proposalId: GroceryListProposalID,
      title: S.String.pipe(S.optional),
      description: S.String.pipe(S.optional),
      status: ProposalStatus.pipe(S.optional),
    },
    success: ProposalWithComments,
    failure: AppRequestFailures,
  },
) {}

// Comment creation
export class CommentCreated extends S.Class<CommentCreated>("CommentCreated")({
  proposalId: GroceryListProposalID,
  ts: CommentTsID,
  authorUserId: OrgUserID,
  content: S.Array(GroceryListCommentContentKind),
  topic: S.String.pipe(S.optional),
  replyToTs: CommentTsID.pipe(S.optional),
}) {}

export class CreateComment extends S.TaggedRequest<CreateComment>()(
  "CreateComment",
  {
    payload: {
      proposalId: GroceryListProposalID,
      content: S.Array(GroceryListCommentContentKind),
      topic: S.String.pipe(S.optional),
      replyToTs: CommentTsID.pipe(S.optional),
    },
    success: CommentCreated,
    failure: AppRequestFailures,
  },
) {}

export class CommentsRetrieved extends S.Class<CommentsRetrieved>(
  "CommentsRetrieved",
)({
  comments: S.Array(CommentInfo),
}) {}

export class ListComments extends S.TaggedRequest<ListComments>()(
  "ListComments",
  {
    payload: {
      proposalId: GroceryListProposalID,
      topic: S.String.pipe(S.optional),
    },
    success: CommentsRetrieved,
    failure: AppRequestFailures,
  },
) {}

// User subscription status
export class SubscriptionStatus extends S.Class<SubscriptionStatus>(
  "SubscriptionStatus",
)({
  proposalId: GroceryListProposalID,
  lastReadCommentTs: CommentTsID,
}) {}

export class GetUserSubscriptionStatus extends S.TaggedRequest<GetUserSubscriptionStatus>()(
  "GetUserSubscriptionStatus",
  {
    payload: {
      proposalId: GroceryListProposalID,
    },
    success: SubscriptionStatus,
    failure: AppRequestFailures,
  },
) {}

export class UpdateUserSubscriptionStatus extends S.TaggedRequest<UpdateUserSubscriptionStatus>()(
  "UpdateUserSubscriptionStatus",
  {
    payload: {
      proposalId: GroceryListProposalID,
      lastReadCommentTs: CommentTsID,
    },
    success: SubscriptionStatus,
    failure: AppRequestFailures,
  },
) {}

// Delete proposal
export class DeleteProposal extends S.TaggedRequest<DeleteProposal>()(
  "DeleteProposal",
  {
    payload: {
      proposalId: GroceryListProposalID,
    },
    success: S.Void,
    failure: AppRequestFailures,
  },
) {}

// Add this new class for the comment update request
export class UpdateComment extends S.TaggedRequest<UpdateComment>()(
  "UpdateComment",
  {
    payload: {
      proposalId: GroceryListProposalID,
      commentTs: CommentTsID,
      content: S.Array(GroceryListCommentContentKind),
    },
    success: CommentInfo,
    failure: AppRequestFailures,
  },
) {}

// Add this new class for the merge proposal request
export class MergeProposalResult extends S.Class<MergeProposalResult>(
  "MergeProposalResult",
)({
  proposalId: GroceryListProposalID,
  newStatus: ProposalStatus,
  mergedByUserId: OrgUserID,
  mergedAt: S.Date,
}) {}

export class MergeProposal extends S.TaggedRequest<MergeProposal>()(
  "MergeProposal",
  {
    payload: {
      proposalId: GroceryListProposalID,
    },
    success: MergeProposalResult,
    failure: AppRequestFailures,
  },
) {}

// Add these new classes for approve and reject proposal requests
export class ApproveProposalResult extends S.Class<ApproveProposalResult>(
  "ApproveProposalResult",
)({
  proposalId: GroceryListProposalID,
  newStatus: ProposalStatus,
  approvedByUserId: OrgUserID,
  approvedAt: S.DateFromNumber,
}) {}

export class ApproveProposal extends S.TaggedRequest<ApproveProposal>()(
  "ApproveProposal",
  {
    payload: {
      proposalId: GroceryListProposalID,
    },
    success: ApproveProposalResult,
    failure: AppRequestFailures,
  },
) {}

export class RejectProposalResult extends S.Class<RejectProposalResult>(
  "RejectProposalResult",
)({
  proposalId: GroceryListProposalID,
  newStatus: ProposalStatus,
  rejectedByUserId: OrgUserID,
  rejectedAt: S.DateFromNumber,
}) {}

export class RejectProposal extends S.TaggedRequest<RejectProposal>()(
  "RejectProposal",
  {
    payload: {
      proposalId: GroceryListProposalID,
    },
    success: RejectProposalResult,
    failure: AppRequestFailures,
  },
) {}

// Add this new class for opening a proposal
export class OpenProposalResult extends S.Class<OpenProposalResult>(
  "OpenProposalResult",
)({
  proposalId: GroceryListProposalID,
  newStatus: ProposalStatus,
  openedByUserId: OrgUserID,
  openedAt: S.DateFromNumber,
}) {}

export class OpenProposal extends S.TaggedRequest<OpenProposal>()(
  "OpenProposal",
  {
    payload: {
      proposalId: GroceryListProposalID,
    },
    success: OpenProposalResult,
    failure: AppRequestFailures,
  },
) {}
