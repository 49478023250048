import { Button } from "@/components/ui/button";
import { useCurrentUser } from "@/contexts/CurrentUserContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export function WhoAmI() {
  const { currentUser, clearAllData, refreshAll } = useCurrentUser();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    clearAllData();
  };

  const refreshMutation = useMutation({
    mutationFn: refreshAll,
    onSuccess: () => {
      // Invalidate and refetch any queries that depend on the user data
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    },
  });

  const handleRefresh = () => {
    refreshMutation.mutate();
  };

  return (
    <div $="space-y-4">
      <pre $="max-h-[60vh] overflow-y-auto text-xs/3">
        {JSON.stringify(
          currentUser,
          (_, value) =>
            String(value).startsWith("eyJ")
              ? String(value).slice(0, 20) + "..."
              : value,
          2,
        )}
      </pre>
      <div $="flex justify-between">
        <Button $ onClick={handleRefresh} disabled={refreshMutation.isPending}>
          {refreshMutation.isPending ? "Refreshing..." : "Refresh"}
        </Button>
        <Button $ onClick={handleLogout}>
          Logout
        </Button>
      </div>
      {refreshMutation.isError && (
        <div $="text-red-500">
          An error occurred: {refreshMutation.error.message}
        </div>
      )}
    </div>
  );
}
