import { listen } from "@/lib/dev/listen";
import { runOnRepeat, throttle } from "@phosphor/prelude";
import { useEffect } from "react";

type CheckFn = void | (() => void);
export function useCheckEffect(
  makeCheckFn: () => CheckFn,
  deps: readonly unknown[],
  { interval = 5000 } = {},
) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: it's a function
  useEffect(() => {
    const checkFn = makeCheckFn();
    if (!checkFn) return;
    const check = throttle(1000, checkFn);
    let subRepeat = runOnRepeat(interval, check);
    const subOnFocus = listen(window, "focus", () => {
      subRepeat();
      subRepeat = runOnRepeat(interval, check);
    });
    const subBlur = listen(window, "blur", () => subRepeat());
    return () => {
      subRepeat();
      subOnFocus();
      subBlur();
      check.cancel();
    };
  }, deps);
}
