import { Schema } from "@effect/schema";
import { Either, pipe } from "effect";
import { useEffect, useState } from "react";

export function useStorageValue<T extends Schema.Struct.Fields>(
  key: string,
  storage: Storage,
  fields: () => T,
): [
  Schema.Struct.Type<T> | null,
  (
    value:
      | Schema.Struct.Type<T>
      | null
      | ((prev: Schema.Struct.Type<T> | null) => Schema.Struct.Type<T> | null),
  ) => void,
] {
  type Decoded = Schema.Struct.Type<T>;
  const [schema] = useState(() => Schema.Struct(fields()));
  const [state, setStateInternal] = useState<Decoded | null>(() => {
    const storedValue = storage.getItem(key);
    if (storedValue) {
      return pipe(
        storedValue,
        // @ts-ignore
        Schema.decodeUnknownEither(Schema.parseJson(schema)),
        Either.map((a) => a as Decoded),
        Either.getOrElse((err) => {
          console.error("Parse Error (will replace with null)", {
            storedValue,
            err,
          });
          return null;
        }),
      );
    }
    return null;
  });

  useEffect(() => {
    if (state === null) {
      storage.removeItem(key);
    } else {
      const encodedState = pipe(
        state,
        // @ts-ignore
        Schema.encodeSync(schema),
      );
      const serializedState = JSON.stringify(encodedState);
      storage.setItem(key, serializedState);
    }
  }, [key, state, storage, schema]);

  return [state, setStateInternal];
}
