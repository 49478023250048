import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { getColorStyles } from "@/hooks/useColorStyles";
import { Color } from "@phosphor/server";
import { useState } from "react";

interface PickColorModalProps {
  isOpen: boolean;
  initialColor: Color;
  onChosen: (newColor: Color) => void;
  onDismiss: () => void;
}

const colorOptions: Color[] = [
  // Red
  { hue: 10, chroma: 0.1 },
  { hue: 10, chroma: 0.25 },
  // Orange
  { hue: 50, chroma: 0.1 },
  { hue: 50, chroma: 0.25 },
  // Yellow
  { hue: 90, chroma: 0.1 },
  { hue: 90, chroma: 0.25 },
  // Green
  { hue: 140, chroma: 0.1 },
  { hue: 140, chroma: 0.25 },
  // Teal
  { hue: 180, chroma: 0.1 },
  { hue: 180, chroma: 0.25 },
  // Blue
  { hue: 240, chroma: 0.1 },
  { hue: 240, chroma: 0.25 },
  // Purple
  { hue: 280, chroma: 0.1 },
  { hue: 280, chroma: 0.25 },
  // Pink
  { hue: 330, chroma: 0.1 },
  { hue: 330, chroma: 0.25 },
  // Grays (chroma 0.02)
  { hue: 10, chroma: 0.02 },
  { hue: 70, chroma: 0.02 },
  { hue: 130, chroma: 0.02 },
  { hue: 240, chroma: 0.02 },
];

export function PickColorModal({
  isOpen,
  initialColor,
  onChosen,
  onDismiss,
}: PickColorModalProps) {
  const [selectedColor, setSelectedColor] = useState<Color>(initialColor);

  const handleColorSelect = (color: Color) => {
    setSelectedColor(color);
    onChosen(color);
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onDismiss()}>
      <DialogContent $="sm:max-w-[425px]">
        <DialogHeader $>
          <DialogTitle $>Choose a Color</DialogTitle>
        </DialogHeader>
        <div $="grid grid-cols-4 gap-4">
          {colorOptions.map((color, index) => (
            <button
              $="w-16 h-16 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-transform hover:scale-110"
              key={index}
              style={{
                ...getColorStyles(color).colorPreview,
                border:
                  selectedColor.hue === color.hue &&
                  selectedColor.chroma === color.chroma
                    ? "2px solid black"
                    : "none",
              }}
              onClick={() => handleColorSelect(color)}
              aria-label={`Select color with hue ${color.hue} and chroma ${color.chroma}`}
            />
          ))}
        </div>
        <div $="flex justify-end space-x-2 mt-4">
          <Button $ variant="outline" onClick={onDismiss}>
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
