import { AddImageModal } from "@/components/AddImageModal";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import { parseURLFromStringOrUndefined } from "@phosphor/prelude";
import { UserAPI } from "@phosphor/server";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useCurrentOrg } from "./$org._layout";

export const Route = createFileRoute("/$org/_layout/settings/profile")({
  component: ProfileSettingsComponent,
});

function ProfileSettingsComponent() {
  const { org, user } = useCurrentOrg();
  const [isEditing, setIsEditing] = useState(false);

  const { data: userProfile, refetch } = useRpcQuery({
    queryKey: ["user", user.id],
    orgID: org.id,
    request: new UserAPI.GetUserProfile({ userID: user.id }),
  });

  return userProfile ? (
    isEditing ? (
      <EditProfileForm
        userProfile={userProfile}
        onCancel={() => setIsEditing(false)}
        onSuccess={() => {
          setIsEditing(false);
          refetch();
        }}
      />
    ) : (
      <ReadOnlyProfile
        userProfile={userProfile}
        onEdit={() => setIsEditing(true)}
      />
    )
  ) : (
    <div $="p-4 max-w-2xl mx-auto"></div>
  );
}

function ReadOnlyProfile({
  userProfile,
  onEdit,
}: {
  userProfile: UserAPI.UserProfile;
  onEdit: () => void;
}) {
  return (
    <div $="p-4 max-w-2xl mx-auto">
      <h2 $="text-2xl font-bold mb-4">Profile Settings</h2>
      <div $="flex items-center gap-4 mb-6">
        <Avatar $="w-12 h-12">
          <AvatarImage
            $
            alt="User avatar"
            src={userProfile.photoURL?.toString()}
          />
          <AvatarFallback $>
            <Ta.IconUser $="w-full h-full" />
          </AvatarFallback>
        </Avatar>
        <div $="flex-grow">
          <h3 $="text-xl font-semibold flex items-center gap-2">
            {userProfile?.profileName}
            <Button
              $
              variant="ghost"
              size="icon"
              onClick={onEdit}
              aria-label="Edit profile"
            >
              <Ta.IconPencil $="w-4 h-4" />
            </Button>
          </h3>
          <p $="text-gray-600">@{userProfile?.displayName}</p>
        </div>
      </div>
    </div>
  );
}

function EditProfileForm({
  userProfile,
  onCancel,
  onSuccess,
}: {
  userProfile: UserAPI.UserProfile;
  onCancel: () => void;
  onSuccess: () => void;
}) {
  const { org, user } = useCurrentOrg();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const form = useForm({
    defaultValues: {
      profileName: userProfile?.profileName ?? "",
      displayName: userProfile?.displayName ?? "",
      photoURL: userProfile?.photoURL?.toString() ?? "",
    },
  });

  const updateProfileMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: UserAPI.UpdateUserProfile) => input,
    onSuccess,
  });

  const onSubmit = form.handleSubmit((data) => {
    updateProfileMutation.mutate(
      new UserAPI.UpdateUserProfile({
        userID: user.id,
        profileName: data.profileName || undefined,
        displayName: data.displayName || undefined,
        photoURL: data.photoURL
          ? parseURLFromStringOrUndefined(data.photoURL)
          : null,
      }),
    );
  });

  const handleImageChosen = (newURL: string) => {
    form.setValue("photoURL", newURL);
    setIsImageModalOpen(false);
  };

  return (
    <div $="p-4 max-w-2xl mx-auto">
      <h2 $="text-2xl font-bold mb-4">Edit Profile</h2>
      <Form {...form}>
        <form onSubmit={onSubmit} $="space-y-4">
          <div $="flex items-center gap-4 mb-6">
            <Button
              $="p-2 rounded-sm"
              type="button"
              variant="outline"
              size="icon"
              onClick={() => setIsImageModalOpen(true)}
            >
              <Avatar $="w-8 h-8">
                <AvatarImage $ alt="User avatar" src={form.watch("photoURL")} />
                <AvatarFallback $>
                  <Ta.IconUser $="w-full h-full" />
                </AvatarFallback>
              </Avatar>
            </Button>
            <span $="text-sm text-gray-600">
              Click to change profile picture
            </span>
          </div>
          <FormField
            control={form.control}
            name="profileName"
            render={({ field }) => (
              <FormItem $>
                <FormLabel $>Profile Name</FormLabel>
                <FormControl $>
                  <Input $ placeholder="Profile Name" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="displayName"
            render={({ field }) => (
              <FormItem $>
                <FormLabel $>Display Name</FormLabel>
                <FormControl $>
                  <Input $ placeholder="Display Name" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <div $="flex justify-end gap-2">
            <Button $ type="button" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button $ type="submit">
              Save Changes
            </Button>
          </div>
        </form>
      </Form>
      <AddImageModal
        isOpen={isImageModalOpen}
        initialURL={form.watch("photoURL")}
        onChosen={handleImageChosen}
        onDismiss={() => setIsImageModalOpen(false)}
        modalTitle="Choose Profile Picture"
      />
    </div>
  );
}
