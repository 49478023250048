import { S } from "@phosphor/prelude";
import {
  AccountID,
  AppRequestFailures,
  JWTAccessToken,
  OrgID,
  OrgInfo,
  OrgUserID,
  OrgUserInfo,
} from "../common";

export class CreatedAccountWithToken extends S.TaggedClass<CreatedAccountWithToken>()(
  "CreatedAccountWithToken",
  {
    id: AccountID,
    securityEmail: S.String,
    accessToken: JWTAccessToken,
    displayHint: S.String,
  },
) {}

export class CreateAccountWithPasswordLogin extends S.TaggedRequest<CreateAccountWithPasswordLogin>()(
  "CreateAccountWithPasswordLogin",
  {
    failure: AppRequestFailures,
    success: CreatedAccountWithToken,
    payload: {
      displayName: S.String,
      username: S.String,
      securityEmail: S.String,
      password: S.String,
    },
  },
) {}

export class AddPasswordLogin extends S.TaggedRequest<AddPasswordLogin>()(
  "AddPasswordLogin",
  {
    failure: AppRequestFailures,
    success: S.Void,
    payload: {
      accountId: AccountID,
      username: S.String,
      securityEmail: S.String,
      password: S.String,
    },
  },
) {}

export class UserToken extends S.TaggedClass<UserToken>()("UserToken", {
  accessToken: JWTAccessToken,
}) {}

export class CreateUserToken extends S.TaggedRequest<CreateUserToken>()(
  "CreateUserToken",
  {
    failure: AppRequestFailures,
    success: UserToken,
    payload: {
      accountId: AccountID,
      userId: OrgUserID,
      orgId: OrgID,
    },
  },
) {}

export class WhoAmIResponse extends S.TaggedClass<WhoAmIResponse>()(
  "WhoAmIResponse",
  {
    account: S.Struct({
      id: AccountID,
    }).pipe(S.optional),
    user: S.Struct({
      info: OrgUserInfo,
      org: OrgInfo,
    }).pipe(S.optional),
  },
) {}

export class WhoAmI extends S.TaggedRequest<WhoAmI>()("WhoAmI", {
  failure: AppRequestFailures,
  success: WhoAmIResponse,
  payload: {},
}) {}

export class ListAccountUsersResponse extends S.TaggedClass<ListAccountUsersResponse>()(
  "ListAccountUsersResponse",
  {
    users: S.Array(
      S.Struct({
        info: OrgUserInfo,
        org: OrgInfo,
      }),
    ),
  },
) {}

export class ListAccountUsers extends S.TaggedRequest<ListAccountUsers>()(
  "ListAccountUsers",
  {
    failure: AppRequestFailures,
    success: ListAccountUsersResponse,
    payload: {},
  },
) {}

export class LoginWithEmail extends S.TaggedRequest<LoginWithEmail>()(
  "LoginWithEmail",
  {
    failure: AppRequestFailures,
    success: CreatedAccountWithToken,
    payload: {
      email: S.String,
      password: S.String,
    },
  },
) {}
