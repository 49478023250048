import { AccountLogin } from "@/components/AccountLogin";
import { OrgRootNavbar } from "@/components/OrgRootNavbar";
import { OrgWorkspaceNavbar } from "@/components/OrgWorkspaceNavbar";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useCurrentUser } from "@/contexts/CurrentUserContext";
import type { OrgInfo, OrgUserInfo } from "@phosphor/server";
import {
  Outlet,
  createFileRoute,
  useLocation,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { createContext, useContext, useMemo, useState } from "react";

export const Route = createFileRoute("/$org/_layout")({
  component: LayoutComponent,
});

interface CurrentOrgContextType {
  org: OrgInfo;
  user: OrgUserInfo;
}

const CurrentOrgContext = createContext<CurrentOrgContextType | null>(null);

export const useCurrentOrg = (): CurrentOrgContextType => {
  const context = useContext(CurrentOrgContext);
  if (!context) {
    throw new Error(
      "useCurrentOrg must be used within the $org/ route (provided by the $org._layout.tsx file)",
    );
  }
  return context;
};

export const useCurrentOrgOptional = (): CurrentOrgContextType | null => {
  return useContext(CurrentOrgContext);
};

function AccessMessage({
  orgName,
  isLoggedIn,
}: { orgName: string; isLoggedIn: boolean }) {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const navigate = useNavigate();

  return (
    <Card $="max-w-md mx-auto mt-8">
      <CardHeader $>
        <CardTitle $>
          {isLoggedIn ? `No Access to ${orgName}` : `Log into ${orgName}`}
        </CardTitle>
        <CardDescription $>
          {isLoggedIn
            ? "You don't have permission to access this organization."
            : "Please log in to access this organization."}
        </CardDescription>
      </CardHeader>
      <CardContent $="space-y-4">
        {isLoggedIn && (
          <p $>
            Please contact the organization administrator for assistance or log
            in with a different account.
          </p>
        )}
        {showLoginForm ? (
          <AccountLogin canSignup={false} />
        ) : (
          <div $="flex justify-between">
            <Button $ onClick={() => setShowLoginForm(true)}>
              {isLoggedIn ? "Login as Another Account" : "Login"}
            </Button>
            {isLoggedIn && (
              <Button $ variant="outline" onClick={() => navigate({ to: "/" })}>
                Return Home
              </Button>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

function LayoutComponent() {
  const { org } = useParams({ from: "/$org" });
  const { currentUser } = useCurrentUser();
  const { pathname, searchStr } = useLocation();
  const navigate = useNavigate();

  const orgContext = useMemo(() => {
    const orgUser = currentUser.users.find(
      (user) => user.orgInfo.urlKey === org || user.orgInfo.id === org,
    );

    if (orgUser && orgUser.orgInfo.urlKey !== org) {
      // Replace only the org part in the current path
      const newPath = pathname.replace(`/${org}`, `/${orgUser.orgInfo.urlKey}`);
      setTimeout(() => {
        navigate({
          to: newPath + searchStr,
          replace: true,
        });
      }, 50);
    }

    return orgUser ? { org: orgUser.orgInfo, user: orgUser.userInfo } : null;
  }, [currentUser, org, navigate, pathname, searchStr]);

  if (!orgContext) {
    return <AccessMessage orgName={org} isLoggedIn={!!currentUser.account} />;
  }

  const isWorkspaceRoute = pathname.includes("/workspace/");
  return (
    <CurrentOrgContext.Provider value={orgContext}>
      <div $>
        {isWorkspaceRoute ? <OrgWorkspaceNavbar /> : <OrgRootNavbar />}
        <hr $ />
        <Outlet />
      </div>
    </CurrentOrgContext.Provider>
  );
}
