import { S, URLFromString } from "@phosphor/prelude";
import {
  AccountID,
  InternalError,
  JWTAccessToken,
  LoginHandoffKey,
} from "../common";

/** See {@link CreateLoginHandoff} */
export class LoginHandoffCreated extends S.TaggedClass<LoginHandoffCreated>()(
  "LoginHandoffCreated",
  {
    /** The URL to open for the user to login (the client doesn't care what service is used) */
    loginURL: URLFromString,
    expiresAfter: S.DateFromNumber,
    handoffKey: LoginHandoffKey,
  },
) {}

/**
 * Logging in with WorkOS (or any other Identity provider (idP)).
 * 1. Create this handoff
 * 2. Redirect the user to the login URL
 * 3. WorkOS (or other provider) redirects the user back to
 *    the app with a code (see "workos-router.ts")
 * 4. The app uses the code + state to update the handoff
 *    in the database
 * 5. Call {@link CheckLoginHandoff} to see that the handoff
 *    is complete & we exchange the handoff for a JWT
 */
export class CreateLoginHandoff extends S.TaggedRequest<CreateLoginHandoff>()(
  "CreateLoginHandoff",
  {
    payload: { expiresAfter: S.DurationFromMillis },
    success: LoginHandoffCreated,
    failure: InternalError,
  },
) {}

export class HandoffCompleted extends S.TaggedClass<HandoffCompleted>()(
  "HandoffCompleted",
  {
    accessToken: JWTAccessToken,
    accountID: AccountID,
    accountLoginDisplayHint: S.String,
  },
) {}

export class HandoffExpiredOrMissing extends S.TaggedClass<HandoffExpiredOrMissing>()(
  "HandoffExpiredOrMissing",
  {},
) {}

export class HandoffPending extends S.TaggedClass<HandoffPending>()(
  "HandoffPending",
  {},
) {}

export class CheckLoginHandoff extends S.TaggedRequest<CheckLoginHandoff>()(
  "CheckLoginHandoff",
  {
    payload: { handoffKey: LoginHandoffKey },
    success: S.Union(HandoffCompleted, HandoffExpiredOrMissing, HandoffPending),
    failure: InternalError,
  },
) {}
