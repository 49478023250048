/** Throttle will immediately invoke the function, but prevent an additional invocation for the duration of the timeout. */
export function throttle<T>(spaceMs: number, fn: (value: T) => void) {
  spaceMs += Math.random() * 10;
  let lastCall = 0;
  let timeout: number | Timer | undefined;
  return Object.assign(
    (value: T) => {
      const now = Date.now();
      clearTimeout(timeout);
      const elapsed = now - lastCall;
      if (elapsed >= spaceMs) {
        fn(value);
        lastCall = now;
      } else {
        timeout = setTimeout(() => {
          fn(value);
          lastCall = Date.now();
        }, spaceMs - elapsed);
      }
    },
    { cancel: () => clearTimeout(timeout) },
  );
}
