import { useRpcQuery } from "@/hooks/use-rpc-hooks";
import { OrgAPI } from "@phosphor/server";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import { useCurrentOrg } from "./$org._layout";

export const Route = createFileRoute("/$org/_layout/admin/_layout")({
  component: LayoutComponent,
});

function LayoutComponent() {
  const { org } = useCurrentOrg();
  const { data: orgInfo } = useRpcQuery({
    orgID: org.id,
    queryKey: ["org", org.id, "info"],
    request: new OrgAPI.GetOrgInfo({ orgID: org.id }),
  });
  return (
    <div $="p-12 border-purple-400">
      <h1 $="text-2xl font-bold flex items-center gap-2 mb-4">
        {orgInfo?.orgInfo.logoUrl && (
          <img
            $="w-8 h-8 rounded-full aspect-square"
            src={orgInfo.orgInfo.logoUrl.toString()}
            alt={orgInfo.orgInfo.displayName}
          />
        )}
        <Link $="align-baseline" to="/$org/admin" params={{ org: org.urlKey }}>
          {orgInfo?.orgInfo.displayName}{" "}
          <span $="text-sm text-gray-500">Admin</span>
        </Link>
      </h1>
      <Outlet />
    </div>
  );
}
