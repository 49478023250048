import { ParseResult, Schema } from "@effect/schema";

export { Effect as E, pipe, Option as O } from "effect";
export { Schema as S, AST } from "@effect/schema";
export * from "./html-strings.js";
export * from "./user-agents.js";
export * from "./truthy-and-when.js";
export * from "./template-strings.js";
export * from "./debounce.js";
export * from "./throttle.js";

// fix node type for URL interface
type URL = InstanceType<typeof URL>;
export const call = <T>(fn: () => T): T => fn();
export function runOnRepeat(
  spaceMs: number,
  fn: (done: () => void) => void,
): () => void {
  let timeout: number | Timer | undefined;
  spaceMs += Math.random() * 10;
  const done = () => clearTimeout(timeout);
  function run() {
    timeout = setTimeout(run, spaceMs);
    fn(done);
  }
  run();

  return done;
}

export const callOnce = <F extends (...args: any[]) => any>(fn: F) => {
  let called = false;
  let result: ReturnType<F>;
  return (...args: Parameters<F>) => {
    if (called) return result;
    result = fn(...args);
    called = true;
    return result;
  };
};

export const delayPromise = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/** Custom schema that transforms a string to a URL and vice versa. */
export const URLFromString: Schema.Schema<
  InstanceType<typeof URL>,
  string
> = Schema.transformOrFail(
  Schema.String, // The input type is String.
  Schema.declare((u): u is URL => u instanceof URL), // Declaring output type as URL.
  {
    strict: false,
    decode: (input, _, ast) => {
      try {
        const url = new URL(input);
        return ParseResult.succeed(url); // Successfully parsed the URL.
      } catch (error) {
        return ParseResult.fail(
          new ParseResult.Type(ast, input, `Invalid URL (${input})`),
        ); // Parsing failed, return an error.
      }
    },
    encode: (url) => ParseResult.succeed(url.toString()), // Convert URL object back to string.
  },
);
// TODO: Add an arbitrary instance generator
// .pipe(Schema.annotations({
//   arbitrary() {
//     return Arbitrary.make(URLFromString);
//   },
// }));

export const parseURLFromStringOrUndefined = (
  input: string | undefined | null,
): URL | undefined => {
  if (!input) return undefined;
  try {
    return new URL(input);
  } catch (error) {
    return undefined;
  }
};

export class DevString {
  constructor(
    readonly message: unknown[],
    public readonly cause?: undefined | DevString,
    public readonly context?:
      | undefined
      | Record<string, string | number | boolean>,
  ) {}
  ctx(add: Record<string, string | number | boolean>) {
    return new DevString(this.message, this.cause, {
      ...this.context,
      ...add,
    });
  }
  toMessageString(): string {
    return this.message
      .map((m) =>
        typeof m === "string"
          ? m
          : m && (typeof m === "object" || typeof m === "function")
            ? m instanceof DevString
              ? m.toMessageString()
              : "[object]"
            : String(m),
      )
      .join(" ");
  }
  toString(): string {
    return (
      this.message
        .map((m) => (typeof m === "string" ? m : JSON.stringify(m)))
        .join(" ") +
      (this.context
        ? `\n context:\n  ${Object.entries(this.context)
            .map(([key, value]) => `${key}: ${value}`)
            .join("\n  ")}`
        : "") +
      (this.cause ? `\n (because: ${this.cause.toString()})` : "")
    );
  }
  toJSON() {
    return {
      message: this.message,
      cause: this.cause,
      context: this.context,
    };
  }
  because(cause: DevString) {
    return new DevString(this.message, cause);
  }
}

const interleve = <T, U>(
  a: ReadonlyArray<T>,
  b: ReadonlyArray<U>,
): (T | U)[] => {
  const result: (T | U)[] = [];
  for (let i = 0; i < a.length; i++) {
    result.push(a[i]);
    if (i < b.length) {
      result.push(b[i]);
    }
  }
  return result;
};

export const rethrowWith =
  <T, R>(reason: DevString, fn: (value: T) => R) =>
  (value: T): R => {
    try {
      return fn(value);
    } catch (err) {
      invariant(err instanceof Error, "Expected an Error thrown", err);
      throw new Error(`${err.name} (${reason.toString()}): ${err.message}`, {
        cause: err,
      });
    }
  };

export const dev = (template: TemplateStringsArray, ...args: unknown[]) =>
  new DevString(interleve(template, args));

export function invariant(
  condition: unknown,
  message: string,
  found?: unknown,
): asserts condition {
  if (!condition) {
    console.error(
      "Invariant failed",
      message,
      ...(found ? ["\found:", found] : []),
    );
    throw new Error(message);
  }
}
