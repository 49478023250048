import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import type { InternalError } from "@phosphor/server";
import { createContext, useContext, useState } from "react";

const LAST_ERROR_REPORT_KEY = "lastErrorReportTimestamp";
const ERROR_REPORT_COOLDOWN = 2 * 60 * 1000; // 2 minutes in milliseconds

const createErrorReportEmail = (error: InternalError) =>
  `mailto:oliver@phosphor.co?subject=Internal%20Error%20Report&body=${encodeURIComponent(
    `Error Reference ID: ${error.referenceID}\nEnvironment: ${error.environment}\n\n[Please share what you were doing when this error occurred and attach any relevant details.]`,
  )}`;

interface ConfirmOptions {
  title: string;
  description: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirmed: () => void;
  onCancelled: () => void;
}

interface NotificationsContextType {
  reportInternalError: (when: string, error: InternalError) => void;
  confirm: (options: ConfirmOptions) => void;
}

const context = createContext<NotificationsContextType>({
  reportInternalError: (when: string, error: InternalError) => {
    console.error("Internal Error", error);
    // ... (rest of the existing reportInternalError logic)
  },
  confirm: () => {
    console.warn("Confirmation dialog not implemented");
  },
});

export const useNotifications = () => useContext(context);

export const ProvideNotifications = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confirmOptions, setConfirmOptions] = useState<ConfirmOptions | null>(
    null,
  );

  const reportInternalError = (when: string, error: InternalError) => {
    console.error("Internal Error", error);
    if (!error.referenceID) return;

    const now = Date.now();
    const lastReportTime = Number(
      localStorage.getItem(LAST_ERROR_REPORT_KEY) || 0,
    );

    if (now - lastReportTime < ERROR_REPORT_COOLDOWN) {
      console.debug("Error reporting skipped due to cooldown");
      return;
    }

    setConfirmOptions({
      title: "Internal Error",
      description: `Error ${when}\nWould you mind emailing the details for us?\n\nreference: ${error.referenceID}\nenvironment: ${error.environment}`,
      onConfirmed: () => {
        window.open(createErrorReportEmail(error), "_blank");
      },
      onCancelled: () => {},
    });
    localStorage.setItem(LAST_ERROR_REPORT_KEY, now.toString());
  };

  const handleConfirm = () =>
    setConfirmOptions((confirmOptions) => {
      confirmOptions?.onConfirmed();
      return null;
    });

  const handleCancel = () =>
    setConfirmOptions((confirmOptions) => {
      confirmOptions?.onCancelled();
      return null;
    });

  return (
    <context.Provider
      value={{ reportInternalError, confirm: setConfirmOptions }}
    >
      {children}
      <AlertDialog open={!!confirmOptions} onOpenChange={handleCancel}>
        <AlertDialogContent $>
          <AlertDialogHeader $>
            <AlertDialogTitle $>{confirmOptions?.title}</AlertDialogTitle>
            <AlertDialogDescription $>
              {confirmOptions?.description}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter $>
            <AlertDialogCancel $ onClick={handleCancel}>
              {confirmOptions?.cancelLabel || "Cancel"}
            </AlertDialogCancel>
            <AlertDialogAction $ onClick={handleConfirm}>
              {confirmOptions?.confirmLabel || "Confirm"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </context.Provider>
  );
};
