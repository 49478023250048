import { S, URLFromString } from "@phosphor/prelude";
import { AppRequestFailures, OrgRole, OrgUserID } from "../common";

/** User profile information */
export class UserProfile extends S.TaggedClass<UserProfile>()("UserProfile", {
  id: OrgUserID,
  profileName: S.String,
  displayName: S.String,
  photoURL: URLFromString.pipe(S.optional),
  role: OrgRole,
}) {}

/** Get user profile information */
export class GetUserProfile extends S.TaggedRequest<GetUserProfile>()(
  "GetUserProfile",
  {
    payload: {
      userID: OrgUserID,
    },
    success: UserProfile,
    failure: AppRequestFailures,
  },
) {}

/** See {@link UpdateUserProfile} */
export class UserProfileUpdated extends S.TaggedClass<UserProfileUpdated>()(
  "UserInfoUpdated",
  {},
) {}

/** Update your org user profile. */
export class UpdateUserProfile extends S.TaggedRequest<UpdateUserProfile>()(
  "UpdateUserProfile",
  {
    payload: {
      userID: OrgUserID,
      profileName: S.NonEmptyTrimmedString.pipe(S.optional),
      displayName: S.NonEmptyTrimmedString.pipe(S.optional),
      photoURL: S.Union(URLFromString, S.Null).pipe(S.optional),
    },
    success: UserProfileUpdated,
    failure: AppRequestFailures,
  },
) {}
