import { Ta } from "@/components/ui/icons";
import { useGroceryList } from "@/contexts/GroceryListContext";
import { GroceryListChangeKind } from "@phosphor/server";
import React from "react";

export const RenderChangeIcon: React.FC<{ change: GroceryListChangeKind }> = ({
  change,
}) => {
  switch (change._tag) {
    case "AddItem":
      return <Ta.IconPlus $="w-4 h-4 text-green-500" />;
    case "RemoveItem":
      return <Ta.IconMinus $="w-4 h-4 text-red-500" />;
    case "UpdateItemTitle":
    case "UpdateItemPrice":
      return <Ta.IconPencil $="w-4 h-4 text-blue-500" />;
    case "UpdateDisplayName":
    case "UpdateDescription":
    case "UpdateColor":
      return <Ta.IconSettings $="w-4 h-4 text-yellow-500" />;
  }
};

export const formatChange = (
  change: GroceryListChangeKind,
  currentState: ReturnType<typeof useGroceryList>["currentState"],
  originalItems: ReturnType<typeof useGroceryList>["originalItems"],
): string => {
  switch (change._tag) {
    case "AddItem":
      return `Added item "${change.title}" with price $${change.price.toFixed(2)}`;
    case "RemoveItem":
      const removedItem = originalItems[change.itemId];
      return `Removed item "${removedItem?.title || "Unknown"}"`;
    case "UpdateItemTitle":
      const originalTitle = originalItems[change.itemId]?.title;
      return `Updated item title from "${originalTitle || "Unknown"}" to "${change.newTitle}"`;
    case "UpdateItemPrice":
      const originalPrice = originalItems[change.itemId]?.price;
      const updatedPriceItem = currentState.items.items.find(
        (item) => item.id === change.itemId,
      );
      return `Updated price for "${updatedPriceItem?.title || "Unknown"}" from $${originalPrice?.toFixed(2) || "Unknown"} to $${change.newPrice.toFixed(2)}`;
    case "UpdateDisplayName":
      return `Updated list name to "${change.newDisplayName}"`;
    case "UpdateDescription":
      return `Updated list description`;
    case "UpdateColor":
      return `Updated list color`;
  }
};
