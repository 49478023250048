import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ActivityContentMock } from "./ActivityContentMock";
import { Ta } from "./ui/icons";

export const WorkspaceDetails = () => {
  return (
    <div $="bg-background shadow-sm">
      <Tabs
        defaultValue="workspace"
        $="w-100 border border-[#D4D4D8] rounded-md"
      >
        <TabsList $="grid w-full grid-cols-2 bg-[#F4F4F5]  p-0">
          <TabsTrigger
            $="data-[state=active]:bg-white data-[state=active]:bg-[#94A2BD]/10 data-[state=active]:text-[#202734] h-[100%] font-medium tracking-widest flex justify-between text-[#202734]"
            value="workspace"
          >
            WORKSPACE DETAILS
            <Ta.IconWindowMaximize
              $="w-[20px] h-[20px] mr-2"
              color={"#7487AA"}
            />
          </TabsTrigger>
          <TabsTrigger
            $="data-[state=active]:bg-white data-[state=active]:text-[#202734] data-[state=active]:bg-[#94A2BD]/10 h-[100%] font-medium tracking-widest flex justify-between text-[#202734]"
            value="activity"
          >
            ACTIVITY
            <Ta.IconWindowMaximize
              $="w-[20px] h-[20px] mr-2"
              color={"#7487AA"}
            />
          </TabsTrigger>
        </TabsList>
        <TabsContent $="p-4" value="workspace">
          <div $="mb-4 mt-2">
            <h3 $="text-sm text-[#8F849EF5] tracking-widest">CORRECTNESS</h3>
            <div $="flex mt-2 bg-[#FAF9FB] rounded-sm border border-gray-200 p-2">
              <div $>
                <p $="font-normal text-[12px]">Model Audit</p>

                <div $="flex items-center">
                  <span $="bg-[#FEE2E2] text-[#EF4444] text-[10px] font-semibold px-2 py-1">
                    3 Alerts
                  </span>
                  <span $="bg-[#FEF9C3] text-[#908401] text-xs font-semibold px-2 py-1">
                    5 Warnings
                  </span>
                </div>
              </div>

              <div $="ml-4">
                <h3 $="text-sm font-semibold text-gray-600">Attribution</h3>
                <span $="text-xs text-gray-800">
                  <span $="text-orange-500">10 items</span> to review
                </span>
              </div>
            </div>
          </div>

          <div $="mb-4">
            <h3 $="text-sm font-semibold text-gray-600">OWNERS</h3>
            <div $="mt-2">
              <div $="flex items-center mb-2">
                <img
                  src="https://via.placeholder.com/24"
                  alt="John Marciano"
                  $="w-6 h-6 rounded-full mr-2"
                />
                <div $>
                  <p $="text-sm font-medium text-gray-800">John Marciano</p>
                  <p $="text-xs text-[#737373]">
                    Tax Equity Lead at Block Sabeth
                  </p>
                </div>
              </div>
              <div $="flex items-center">
                <img
                  src="https://via.placeholder.com/24"
                  alt="Bruno Kibon"
                  $="w-6 h-6 rounded-full mr-2"
                />
                <div $>
                  <p $="text-sm font-medium text-gray-800">Bruno Kibon</p>
                  <p $="text-xs text-[#737373]">Tax Equity Lead at BlockRock</p>
                </div>
              </div>
            </div>
          </div>

          <div $="mb-4">
            <h3 $="text-sm font-semibold text-gray-600">CONNECTIONS</h3>
            <ul $="mt-2">
              <li $="flex items-center mb-2">
                <img
                  src="https://via.placeholder.com/16"
                  alt="Salesforce Warehouse"
                  $="w-4 h-4 mr-2"
                />
                <span $="text-sm text-gray-800">Salesforce Warehouse</span>
              </li>
              <li $="flex items-center mb-2">
                <img
                  src="https://via.placeholder.com/16"
                  alt="Solar Model Forecast 2024"
                  $="w-4 h-4 mr-2"
                />
                <span $="text-sm text-gray-800">Solar Model Forecast 2024</span>
              </li>
              <li $="flex items-center mb-2">
                <img
                  src="https://via.placeholder.com/16"
                  alt="CIBAM Model 2024"
                  $="w-4 h-4 mr-2"
                />
                <span $="text-sm text-gray-800">CIBAM Model 2024</span>
              </li>
            </ul>
          </div>

          <div $>
            <h3 $="text-sm font-semibold text-gray-600">QUICK LINKS</h3>
            <ul $="mt-2">
              <li $="flex items-center mb-2">
                <img
                  src="https://via.placeholder.com/16"
                  alt="February Solar Report"
                  $="w-4 h-4 mr-2"
                />
                <span $="text-sm text-gray-800">February Solar Report</span>
              </li>
              <li $="flex items-center mb-2">
                <img
                  src="https://via.placeholder.com/16"
                  alt="March 2024 Investment Memo"
                  $="w-4 h-4 mr-2"
                />
                <span $="text-sm text-gray-800">
                  March 2024 Investment Memo
                </span>
              </li>
              <li $="flex items-center mb-2">
                <img
                  src="https://via.placeholder.com/16"
                  alt="March 2024 Solar Infra Memo"
                  $="w-4 h-4 mr-2"
                />
                <span $="text-sm text-gray-800">
                  March 2024 Solar Infra Memo
                </span>
              </li>
            </ul>
          </div>
        </TabsContent>
        <TabsContent $="px-4 py-2" value="activity">
          <div $="flex justify-between w-100 items-center border-b-2 border-b-[#D4D4D8]">
            <h2 $="text-[#202734] text-[14px] font-medium pb-2">ACTIVITY</h2>
          </div>
          <ActivityContentMock />
        </TabsContent>
      </Tabs>
    </div>
  );
};
