import { Button } from "@/components/ui/button";
import { Ta } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { RichTextarea } from "@/components/ui/rich-textarea";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useRef, useState } from "react";

export const Route = createFileRoute(
  "/$org/_layout/workspace/$workspace/_layout/model",
)({
  component: WorkspaceModel,
});

function WorkspaceModel() {
  const [variableName, setVariableName] = useState("");
  const [expression, setExpression] = useState("");
  const descriptionRef = useRef(
    "Function **to** calculate the total people in the meeting...",
  ); // Change this to store markdown string

  const handleDescriptionChange = useCallback((markdown: string) => {
    console.log("Description changed:", markdown);
    descriptionRef.current = markdown;
  }, []);

  const handleSave = useCallback(() => {
    console.log("Form saved", {
      variableName,
      expression,
      description: descriptionRef.current,
    });
  }, [variableName, expression]);

  return (
    <div $="flex p-6 bg-white h-[100vh]">
      {/* Left Sidebar */}
      <div $="w-1/3 pr-12 border-r border-r-2 ">
        <h2 $="text-xl font-semibold mb-4">Formulas</h2>
        <div $="mb-4">
          <label
            $="block text-sm font-medium text-gray-700 mb-2"
            htmlFor="variableName"
          >
            Variable Name
          </label>
          <Input
            $
            id="variableName"
            placeholder="Variable Name"
            value={variableName}
            onChange={(e) => setVariableName(e.target.value)}
          />
        </div>
        <div $="mb-4">
          <label
            $="block text-sm font-medium text-gray-700 mb-2"
            htmlFor="expression"
          >
            Expression
          </label>
          <Input
            $
            id="expression"
            placeholder="Type 'if' or 'when' to begin writing an expression"
            value={expression}
            onChange={(e) => setExpression(e.target.value)}
          />
        </div>
        <div $="mb-4">
          <label
            $="block text-sm font-medium text-gray-700 mb-2"
            htmlFor="description"
          >
            Description
          </label>
          <RichTextarea
            $
            id="description"
            defaultContent={descriptionRef.current}
            onDocUpdate={handleDescriptionChange}
            placeholder="Ex: Calculate the total people in the meeting..."
          />
        </div>
        <div $="mb-4 border-b border-b-2">
          <label
            $="block text-sm font-medium text-gray-700 mb-4"
            htmlFor="referencedBy"
          >
            Referenced by
          </label>
        </div>
        <div $="mb-4 flex items-center">
          <h3 $=" text-sm font-medium text-gray-700 ">Advanced options</h3>
          <Ta.IconChevronRight $="w-[18px] h-[18px]  ml-2" />
        </div>
        <div $="flex justify-center gap-2">
          <Button
            $="text-red-700 bg-transparent hover:bg-transparent"
            onClick={() => {
              // Add cancel logic
            }}
          >
            Cancel
          </Button>
          <Button $="rounded-full" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>

      {/* Right Content */}
      <div $="w-2/3 pl-12">
        <h2 $="text-xl text-center font-semibold mb-6">
          Creating Models on Phosphor
        </h2>
        <div $="space-y-6">
          <div $>
            <h3 $="font-semibold">Define your formulas</h3>
            <p $="text-gray-700">
              Enter a new formula or import a model or modules from the Library
              in the Formulas sidebar. You can also import an existing Excel
              model by invoking Shimmer (⌘ K). As you establish formulas, inputs
              and outputs will appear and update in real time to the right.
            </p>
          </div>
          <div $>
            <h3 $="font-semibold">
              Review changes and merge requests with Model Review
            </h3>
            <p $="text-gray-700">
              Your changes are automatically saved and committed to your Base
              Case branch. You can lock your branch so collaborators use merge
              requests to contribute back to your model with oversight.
            </p>
          </div>
          <div $>
            <h3 $="font-semibold">Set collaboration permissions</h3>
            <p $="text-gray-700">
              You can lock Base Case or any branch you work on so collaborators
              must use merge requests to contribute edits to your model. This
              ensures you have accurate version control of the model.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
