import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useCurrentUser } from "@/contexts/CurrentUserContext";
import { useGetModel } from "@/hooks/useModels";
import { branches } from "@/lib/utils";
import { useCurrentOrg } from "@/routes/$org._layout";
import { Link, useNavigate, useRouterState } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import Logo from "../../public/Logo.svg?url";
import TabGenius from "../../public/TabGenius.svg?url";
import dataBook from "../../public/dataBook.svg?url";
import model from "../../public/model.svg?url";
import { Ta } from "./ui/icons";

export const OrgWorkspaceNavbar = () => {
  const { data: model1 } = useGetModel();
  const { currentUser, clearAllData, refreshAll } = useCurrentUser();
  const navigate = useNavigate();
  const [currentModel, setCurrentModel] = useState<string>("Workspace Name");
  const { location } = useRouterState();
  const overview = location.pathname.includes("/workspace/");
  const modelBtn = location.pathname.includes("/model");
  useEffect(() => {
    if (model1) {
      setCurrentModel(model1?.name);
    }
  }, [model1]);
  const space = localStorage.getItem("currentworkspace");
  const { org } = useCurrentOrg();

  if (!space) {
    return (
      <div $="flex items-center justify-center h-screen bg-gray-50">
        <Alert $="max-w-md">
          <Ta.IconAlertTriangle $="h-4 w-4" />
          <AlertTitle $>No workspace found</AlertTitle>
          <AlertDescription $>
            {/* TODO: Hook this up to an error log */}
            We couldn't find a workspace for this organization. This might be an
            error on our side. So, please report it if it was unexpected.
          </AlertDescription>
          <Link $="mt-4" to="/$org" params={{ org: org.urlKey }}>
            Go to Organization Page
          </Link>
        </Alert>
      </div>
    );
  }

  return (
    <div $="sticky min-w-[800px] top-0 bg-[#FAF9FB]">
      <div $=" border border-b-[#D4D4D8]  flex items-center px-4 w-full h-full p-[16px] md:px-6">
        <div $="flex items-center w-[260px] h-[47px]">
          <div $="flex items-center border border-[#D4D4D8] bg-background rounded-sm h-full w-full">
            <Link $ to="/">
              <img
                $="w-100 border-r border-r-[#D4D4D8] pr-[15px] pl-[12px]"
                src={Logo}
              />
            </Link>
            <Ta.IconArrowLeft
              $="w-4 h-4 ml-[15px] mr-[5px] cursor-pointer"
              color="black"
              onClick={() => window.history.back()}
            />
            <h3 $="text-black text-[14px] font-medium leading-tight flex items-center pr-[11px]">
              {space ? space : "Workspace name"}
            </h3>
          </div>
        </div>

        <div $="flex ml-[257px]">
          <Link
            to="/$org/workspace/$workspace"
            params={{ org: org.urlKey, workspace: space }}
            $={`flex items-center text-xs font-medium font-['Inter'] uppercase leading-none tracking-wide 
              ${overview && !modelBtn ? "bg-[#EFEDF3] text-black" : "bg-transparent text-neutral-500"}
              hover:bg-[#EFEDF3] gap-[4px] h-full px-4 py-2`}
          >
            <img $="w-[32px] h-[32px]" src={TabGenius} />
            OVERVIEW
          </Link>
          <Link
            to="/$org/workspace/$workspace/model"
            params={{ org: org.urlKey, workspace: space }}
            $={`flex items-center text-xs font-medium font-['Inter'] uppercase leading-none tracking-wide 
              ${modelBtn ? "bg-[#EFEDF3] text-black" : "bg-transparent text-neutral-500"}
              hover:bg-[#EFEDF3] gap-[4px] h-full px-4 py-2`}
          >
            <img $="w-[32px] h-[32px]" src={model} />
            MODEL
          </Link>
          <Link
            to="/$org/workspace/$workspace/databook"
            params={{ org: org.urlKey, workspace: space }}
            $="flex items-center text-neutral-500 text-xs font-medium font-['Inter'] uppercase leading-none tracking-wide hover:bg-[#EFEDF3] gap-[4px] bg-transparent h-full px-4 py-2"
          >
            <img $="w-[32px] h-[32px]" src={dataBook} />
            DATA BOOK
          </Link>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger $="ml-auto border-none">
            {currentUser && currentUser?.users[0]?.userInfo?.photoURL ? (
              <img
                $="w-10 h-10 rounded-full object-cover"
                src={currentUser?.users[0]?.userInfo?.photoURL.toString()}
                alt="User avatar"
              />
            ) : (
              <Ta.IconUser $="w-8 h-8" />
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent $={""}>
            <DropdownMenuLabel $={""}>
              {currentUser.users[0].userInfo.displayName}
            </DropdownMenuLabel>
            <DropdownMenuSeparator $={""} />

            <DropdownMenuItem
              $
              onClick={() =>
                navigate({
                  to: "/$org/settings/profile",
                  params: { org: org.urlKey },
                  replace: true,
                })
              }
            >
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem $={""} onClick={() => clearAllData()}>
              Logout
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div $="flex justify-between items-center p-[16px] border-b border-[#D4D4D8]">
        <div $="w-[501px] h-[32px] flex gap-[16px]">
          <DropdownMenu>
            <DropdownMenuTrigger $="flex items-center justify-between text-[#202734] text-[12px] font-semibold p-2 border border-[#D4D4D8] rounded-full w-[129px] h-full bg-background hover:bg-[#D4D4D8]">
              <Ta.IconGitMerge $="w-4 h-4" /> Base Case{" "}
              <Ta.IconChevronDown $="w-4 h-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent $="m-2 w-[500px] bg-white border border-[#D4D4D8] rounded-lg shadow-lg max-h-[600px] overflow-y-auto">
              {/* Header Section */}
              <div $="flex items-center justify-between  border-b border-gray-200 px-[8px] py-[10px]">
                <h3 $="text-lg font-bold">Branches</h3>
                <button $="text-gray-400 hover:text-gray-600">
                  <Ta.IconX $="w-4 h-4" />
                </button>
              </div>

              {/* Branch List */}
              <div $="">
                {branches.map((branch) => (
                  <div key={branch.id} $="">
                    {/* Parent Branch */}
                    <DropdownMenuItem
                      $={`border-b border-b-2 block py-[15px] text-black`}
                    >
                      {/* Multiple Parents */}
                      {branch.multipleParents && (
                        <div $="my-1 flex items-center gap-2">
                          <div $="text-xs text-gray-500">Parent Branches:</div>
                          {branch.multipleParents.map((parent) => (
                            <>
                              <span
                                key={parent}
                                $="text-xs bg-gray-200 px-2 py-1 rounded-full"
                              >
                                {parent}
                              </span>
                            </>
                          ))}
                        </div>
                      )}
                      <div $="flex items-center">
                        {branch.isStarred && (
                          <Ta.IconStarFilled $="w-4 h-4 mr-2" />
                        )}
                        <span $="font-medium">{branch.name}</span>
                        {branch.isLocked && <Ta.IconLock $="w-4 h-4 ml-2 " />}
                      </div>
                      <div $="text-xs text-gray-500">
                        {branch.updated} by {branch.updatedBy}
                      </div>
                    </DropdownMenuItem>

                    {/* Child Branches */}
                    {branch.children && (
                      <div $="ml-6">
                        {branch.children.map((child) => (
                          <DropdownMenuItem
                            key={child.id}
                            $="block p-2 text-black hover:bg-gray-50 border-b border-b-2"
                          >
                            <div $="flex items-center">
                              <span $="font-medium">{child.name}</span>
                            </div>
                            <div $="text-xs text-gray-500">
                              {child.updated} by {child.updatedBy}
                            </div>
                          </DropdownMenuItem>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
          <div $="w-[167px] flex border border-[#D4D4D8] rounded-full">
            <Link $="px-2 gap-1 bg-background text-black font-semibold h-auto flex items-center hover:bg-background border-r border-[#D4D4D8] w-[50%] border-l border-l-transparent rounded-l-full">
              <Ta.IconArrowBearRight $="w-4 h-4" />
              <span $="text-[12px] flex-grow">Branch</span>
            </Link>
            <Link $="px-2 gap-1 bg-background text-black font-semibold h-auto flex w-1/2 items-center hover:bg-background border-r border-r-transparent rounded-r-full">
              <Ta.IconDownload $="w-4 h-4" />
              <span $="text-[12px] flex-grow">Save</span>
            </Link>
          </div>
          <div $="w-[183px] h-full">
            <div $="h-full flex items-center justify-between text-black text-[14px] font-semibold border border-[#D4D4D8] rounded-full bg-background ">
              <div $="p-2 h-full flex items-center ">
                <Ta.IconClockEdit $="w-4 h-4" />
                <DropdownMenu>
                  <DropdownMenuTrigger $="flex items-center text-black ml-3 font-semibold text-[12px] ">
                    5/16/24 at 5:30PM
                    <Ta.IconChevronDown $="w-[16px] h-[16px] ml-[6px]" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent $={""}>
                    <DropdownMenuItem $={""}>
                      5/16/24 at 5:30PM
                    </DropdownMenuItem>
                    <DropdownMenuItem $={""}>
                      5/16/24 at 5:12PM
                    </DropdownMenuItem>
                    <DropdownMenuItem $={""}>
                      5/12/24 at 10:10AM
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
        <div $="flex gap-[8px] h-[32px]">
          <Link $="w-[89px] h-full bg-background  flex items-center justify-center gap-[4px] text-black font-semibold text-[12px] rounded-full border border-[#D4D4D8] p-0">
            <Ta.IconBooks $="w-4 h-4" />
            Library
          </Link>
          <Link $="w-[127px] h-full bg-background  flex items-center justify-center gap-[4px] text-black font-semibold text-[12px] rounded-full border border-[#D4D4D8] p-0">
            <Ta.IconUserPlus $="w-4 h-4" />
            Collaborators
          </Link>
        </div>
      </div>
    </div>
  );
};
