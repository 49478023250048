import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useModal } from "@/context/ModalContext";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import { OrgFolderID, OrgID, OrgURLKey, WorkspaceAPI } from "@phosphor/server";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { AppFailureCard } from "./AppFailureDisplay";
import { Button } from "./ui/button";
import { Ta } from "./ui/icons";
import { Input } from "./ui/input";

interface WorkspaceListProps {
  orgID: OrgID;
  orgURLKey: OrgURLKey;
  folder?: OrgFolderID;
}

export const WorkspaceList = ({
  orgID,
  orgURLKey,
  folder,
}: WorkspaceListProps) => {
  const navigate = useNavigate();
  const { isOpen, openModal, closeModal } = useModal();
  const [newWorkspace, setNewWorkspace] = useState<string>("");

  const { data: workspaces } = useRpcQuery({
    orgID,
    queryKey: ["getWorkspaces", folder],
    request: new WorkspaceAPI.GetWorkspaces({ folder }),
  });

  const queryClient = useQueryClient();
  const createWorkspaceMutation = useRpcMutation({
    orgID,
    mutate: (input: WorkspaceAPI.CreateWorkspace) => input,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getWorkspaces", folder] });
      localStorage.setItem("currentworkspace", data.workspace_display_name);
      navigate({
        to: "/$org/workspace/$workspace",
        params: { org: orgURLKey, workspace: data.workspace_id },
      });
      closeModal();
    },
    onError: (error) => {
      console.error("Failed to create organization:", error);
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewWorkspace(event.target.value);
  };

  const handleCreateWorkspace = () => {
    createWorkspaceMutation.mutate(
      new WorkspaceAPI.CreateWorkspace({
        displayName: newWorkspace,
        folder,
      }),
    );
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleCreateWorkspace();
    }
  };

  return (
    <div $="p-5 min-w-[800px]">
      <table $="min-w-full bg-background border border-[#D4D4D8]">
        <thead $>
          <tr $="bg-gray-100 border-b border-gray-200">
            <th $="px-4 py-2 text-left text-heading font-medium text-[14px] tracking-wide">
              NAME
            </th>
            <th $="px-4 py-2 text-left text-heading font-medium text-[14px] tracking-wide">
              OWNER
            </th>
            <th $="px-4 py-2 text-left text-heading font-medium text-[14px] tracking-wide">
              LAST UPDATED
            </th>
          </tr>
        </thead>
        <tbody $>
          {workspaces && workspaces?.length > 0 ? (
            workspaces.map((workspace: WorkspaceAPI.WorkspaceWithUsers) => (
              <tr
                key={workspace.workspaceId}
                $="border-b border-gray-200 cursor-pointer"
                onClick={() => {
                  localStorage.setItem(
                    "currentworkspace",
                    workspace.workspaceDisplayName,
                  );
                  navigate({
                    to: "/$org/workspace/$workspace",
                    params: {
                      org: orgURLKey,
                      workspace: workspace.workspaceId,
                    },
                  });
                }}
              >
                <td $="px-4 py-2">{workspace.workspaceDisplayName}</td>
                <td $="px-4 py-2"></td>
                <td $="px-4 py-2">Not available</td>
              </tr>
            ))
          ) : (
            <tr $="text-center">
              <td colSpan={3} $="py-10">
                <div $="flex flex-col items-center justify-center gap-4">
                  <div $="bg-background border border-[#766D87] p-3 rounded-full">
                    <Ta.IconBrowserPlus
                      $="w-[48px] h-[48px] font-normal"
                      color="#766D87"
                    />
                  </div>
                  <p $="text-gray-700 w-[300px]">
                    Get started by creating a new workspace to create models,
                    and collaborate with peers.
                  </p>
                  <button
                    $="px-4 py-2 bg-[#202734] text-white rounded-full"
                    onClick={openModal}
                  >
                    + Create New Workspace
                  </button>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Dialog open={isOpen} onOpenChange={(open) => !open && closeModal()}>
        <DialogTrigger $ asChild></DialogTrigger>
        <DialogContent $="bg-background p-4">
          <DialogHeader $>
            <DialogTitle $="text-[#0F172A] text-[18px] font-semibold">
              Name your new workspace
            </DialogTitle>
            <DialogDescription $="mt-4 space-y-4">
              <Input
                value={newWorkspace}
                onChange={handleInputChange}
                onKeyUp={handleKeyUp}
                $="bg-background w-full"
              />
              {createWorkspaceMutation.error && (
                <AppFailureCard $ error={createWorkspaceMutation.error} />
              )}
              <div $="flex justify-end gap-2 mt-6">
                <Button
                  $="bg-white text-[#0F172A] hover:bg-gray-100"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  $="bg-[#0F172A] text-white rounded-full hover:bg-[#0F172A]"
                  onClick={handleCreateWorkspace}
                >
                  Continue
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};
