import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useRpcQuery } from "@/hooks/use-rpc-hooks";
import { useCurrentOrg } from "@/routes/$org._layout";
import { OrgUserID, UserAPI } from "@phosphor/server";
import React from "react";

interface UserNameChipProps {
  userId: OrgUserID;
}

export const UserNameChip: React.FC<UserNameChipProps> = ({ userId }) => {
  const { org } = useCurrentOrg();

  const {
    data: userProfile,
    isLoading,
    error,
  } = useRpcQuery({
    queryKey: ["user-profile", userId],
    orgID: org.id,
    request: new UserAPI.GetUserProfile({
      userID: userId,
    }),
  });

  if (isLoading) {
    return (
      <span $="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs">
        Loading...
      </span>
    );
  }

  if (error || !userProfile) {
    return (
      <span $="inline-block bg-red-200 rounded-full px-2 py-1 text-xs">
        Unknown User
      </span>
    );
  }

  return (
    <HoverCard>
      <HoverCardTrigger $>
        <span $="inline-flex items-center bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-xs cursor-pointer">
          <Avatar $="w-4 h-4 mr-1">
            <AvatarImage
              $
              src={userProfile.photoURL?.toString()}
              alt={userProfile.profileName}
            />
            <AvatarFallback $>
              {userProfile.profileName.charAt(0)}
            </AvatarFallback>
          </Avatar>
          {userProfile.displayName}
        </span>
      </HoverCardTrigger>
      <HoverCardContent $="w-80">
        <div $="flex justify-between space-x-4">
          <Avatar $="flex-0">
            <AvatarImage
              $
              src={userProfile.photoURL?.toString()}
              alt={userProfile.profileName}
            />
            <AvatarFallback $>
              {userProfile.profileName.charAt(0)}
            </AvatarFallback>
          </Avatar>
          <div $="space-y-1 flex-grow">
            <h4 $="text-sm font-semibold">{userProfile.profileName}</h4>
            <p $="text-sm text-gray-500">@{userProfile.displayName}</p>
            <div $="flex items-center pt-2">
              <span $="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                {userProfile.role}
              </span>
            </div>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
