import { Ta } from "@/components/ui/icons";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import React, { Suspense, useEffect } from "react";
import { WhoAmI } from "./WhoAmI";
import { addDataLocListeners } from "./addDataLocListeners";
import { openInDevEditor } from "./openInDevEditor";

const TanstackRouterDevtools = React.lazy(() =>
  // Lazy load in development
  import("@tanstack/router-devtools").then((res) => ({
    default: res.TanStackRouterDevtools,
    // For Embedded Mode
    // default: res.TanStackRouterDevtoolsPanel
  })),
);

export const withAppDevtools = (element: React.ReactElement) => {
  // Render nothing in production
  if (process.env.NODE_ENV === "production") return element;

  // const ReactDevtools = getDevtoolsElement();
  return (
    <>
      {element}
      <AppDevtools />
    </>
  );
};

const AppDevtools = () => {
  useEffect(
    () =>
      addDataLocListeners(document.body, ({ file, line, column }) =>
        openInDevEditor(`${file}:${line}:${column}`),
      ),
    [],
  );
  // const ReactDevtools = getDevtoolsElement();
  return (
    <>
      <Suspense>
        <TanstackRouterDevtools />
      </Suspense>
      <div $="absolute bottom-10 left-2 bg-slate-50">
        <Popover>
          <PopoverTrigger $>
            <Ta.IconUser />
          </PopoverTrigger>
          <PopoverContent $="w-[400px]">
            <WhoAmI />
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};
