import { type FinancialModel, financialModels } from "@/lib/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetModels = () => {
  const getModels = async (): Promise<FinancialModel[]> => {
    return financialModels;
  };
  return useQuery({
    queryKey: ["MODELS"],
    queryFn: getModels,
  });
};

export const useGetModel = () => {
  const getModel = async (): Promise<FinancialModel> => {
    return financialModels[0];
  };
  return useQuery({
    queryKey: ["CURRENT_MODEL"],
    queryFn: getModel,
  });
};

export const useGetSelectedModel = () => {
  const queryClient = useQueryClient();
  const getSelectedModel = async (
    name: string,
  ): Promise<FinancialModel | undefined> => {
    const response = financialModels.find(
      (financial) => financial.name === name,
    );
    return response;
  };
  return useMutation({
    mutationFn: getSelectedModel,
    onSuccess(data) {
      queryClient.setQueryData(["CURRENT_MODEL"], data);
    },
  });
};
