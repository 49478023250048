import "prosekit/basic/style.css";
import { cn } from "@/lib/utils";
import { type NodeJSON, createEditor, jsonFromNode } from "prosekit/core";
import type { ProseMirrorNode } from "prosekit/pm/model";
import { ProseKit, useDocChange } from "prosekit/react";
import * as React from "react";
import { useCallback, useMemo } from "react";

import { invariant } from "@phosphor/prelude";
import {
  extension,
  markdownParser,
  markdownSerializer,
  schema,
} from "./rich-textarea/extension";

export interface RichTextareaProps
  extends React.TextareaHTMLAttributes<HTMLDivElement> {
  defaultContent?: string; // Change this to accept markdown string
  onDocUpdate?: (markdown: string) => void; // Change this to return markdown string
}

const RichTextarea = React.forwardRef<HTMLDivElement, RichTextareaProps>(
  ({ className, defaultContent, onDocUpdate, ...props }, ref) => {
    const editor = useMemo(() => {
      let defaultDoc: NodeJSON;
      if (defaultContent) {
        const parsedDoc = markdownParser.parse(defaultContent);
        defaultDoc = jsonFromNode(parsedDoc);
      } else {
        const emptyDoc = schema.topNodeType.createAndFill();
        invariant(emptyDoc, "Empty document should be created");
        defaultDoc = jsonFromNode(emptyDoc);
      }

      return createEditor({
        extension,
        defaultContent: defaultDoc,
      });
    }, [defaultContent]);

    const handleDocChange = useCallback(
      (doc: ProseMirrorNode) => {
        const markdown = markdownSerializer.serialize(doc);
        onDocUpdate?.(markdown);
      },
      [onDocUpdate],
    );
    useDocChange(handleDocChange, { editor });

    return (
      <ProseKit editor={editor}>
        <div
          className={cn(
            "flex min-h-[24px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 overflow-hidden resize-none",
            "box-border h-full min-h-36 overflow-y-auto overflow-x-hidden shadow dark:border-zinc-700 flex flex-col bg-white dark:bg-neutral-900",
            className,
          )}
          ref={ref}
          {...props}
        >
          <div $="relative w-full flex-1 box-border">
            <div
              ref={editor.mount}
              $={cn(
                "ProseMirror box-border min-h-full outline-none outline-0",
                "[&_span[data-mention='user']]:text-blue-500",
                "[&_span[data-mention='tag']]:text-violet-500",
                "[&_pre]:text-white [&_pre]:bg-zinc-800",
              )}
            />
          </div>
        </div>
      </ProseKit>
    );
  },
);

RichTextarea.displayName = "RichTextarea";

export { RichTextarea };
