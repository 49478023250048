/**
 * Lucide Icons for React
 * @deprecated prefer {@link Ta} "Tabler" icons
 */
export * as Lu from "lucide-react";
/**
 * Phosphor Icons for React
 * @deprecated prefer {@link Ta} "Tabler" icons
 */
export * as Ph from "@phosphor-icons/react";
/** Tabler Icons for React (This should be the default to use) */
export * as Ta from "@tabler/icons-react";

import { IconContext } from "@phosphor-icons/react";

export const ProvideIcons = (props: React.PropsWithChildren) => (
  <IconContext.Provider
    value={{
      $: undefined!,
      size: "1em",
      weight: "duotone",
      color: "currentColor",
    }}
  >
    {props.children}
  </IconContext.Provider>
);
