type Truthy<T> = T extends false | "" | null | undefined ? never : T;
export const isTruthy = <T>(value: T): value is Truthy<T> =>
  value === 0 || !!value;
export const when = <T, R>(value: T, then: (truthy: Truthy<T>) => R) => {
  return isTruthy(value) ? then(value) : undefined;
};
export const whenStr = <T, R>(
  value: T,
  then: (truthy: Truthy<T>) => string,
) => {
  return isTruthy(value) ? then(value) : "";
};
export const nonNull = <T>(value: T): value is NonNullable<T> => value != null;
