import { HttpServerRespondable, HttpServerResponse } from "@effect/platform";
import {
  S,
  URLFromString,
  basicHTMLPage,
  html,
  isTruthy,
} from "@phosphor/prelude";
import { pipe } from "effect";

/** What you can log into */
export const AccountID = pipe(S.UUID, S.brand("AccountID"));
export type AccountID = S.Schema.Type<typeof AccountID>;

/** Who you can act on behalf of */
export const OrgUserID = pipe(S.UUID, S.brand("OrgUserID"));
export type OrgUserID = S.Schema.Type<typeof OrgUserID>;

/** Guests can only access things they have been explicitly granted access to. */
export const OrgRole = S.Literal("admin", "member", "guest").pipe(
  S.brand("OrgRole"),
);
export type OrgRole = S.Schema.Type<typeof OrgRole>;

export const JWTAccessToken = S.NonEmptyString.pipe(S.brand("JWTAccessToken"));
export type JWTAccessToken = S.Schema.Type<typeof JWTAccessToken>;
/**
 * The Organization is where responsibilities for billing and auditing
 * are managed.
 */
export const OrgID = pipe(S.UUID, S.brand("OrgID"));
export type OrgID = S.Schema.Type<typeof OrgID>;
/**
 * Equivalent of a "Teamspace" in Notion, or "Space" in Craft.do,
 * this is a space for related things that should all share similar
 * permissioning and access rules.
 */
export const OrgFolderID = pipe(S.UUID, S.brand("OrgFolderID"));
export type OrgFolderID = S.Schema.Type<typeof OrgFolderID>;
/**
 * Workspaces are a collection of items that are all edited and versioned together.
 */
export const WorkspaceID = pipe(S.UUID, S.brand("WorkspaceID"));
export type WorkspaceID = S.Schema.Type<typeof WorkspaceID>;
export const WorkspaceRole = S.Literal("manager", "editor", "suggestor").pipe(
  S.brand("WorkspaceRole"),
);
export type WorkspaceRole = S.Schema.Type<typeof WorkspaceRole>;

export const UserURLKey = pipe(S.NonEmptyTrimmedString, S.brand("UserURLKey"));
export type UserURLKey = S.Schema.Type<typeof UserURLKey>;

export const OrgURLKey = pipe(S.NonEmptyTrimmedString, S.brand("OrgURLKey"));
export type OrgURLKey = S.Schema.Type<typeof OrgURLKey>;

export const OrgUserInfo = S.Struct({
  id: OrgUserID,
  role: OrgRole,
  profileName: S.String,
  displayName: S.String,
  photoURL: URLFromString.pipe(S.optional),
});
export type OrgUserInfo = S.Schema.Type<typeof OrgUserInfo>;

export const OrgInfo = S.Struct({
  id: OrgID,
  displayName: S.String,
  urlKey: OrgURLKey,
  logoUrl: URLFromString.pipe(S.optional),
});
export type OrgInfo = S.Schema.Type<typeof OrgInfo>;

export const WorkOSAccountID = pipe(
  S.String,
  S.nonEmptyString(),
  S.brand("WorkOSAccountID"),
);
export type WorkOSAccountID = S.Schema.Type<typeof WorkOSAccountID>;

export const LoginHandoffKey = pipe(
  S.UUID,
  S.brand("HandoffKey"),
  S.annotations({
    parseIssueTitle: () => "Invalid login requester",
  }),
);
export type LoginHandoffKey = S.Schema.Type<typeof LoginHandoffKey>;

export const OrgInvitationKey = pipe(
  S.UUID,
  S.brand("OrgInvitationKey"),
  S.annotations({
    parseIssueTitle: () => "Invalid invitation",
  }),
);
export type OrgInvitationKey = S.Schema.Type<typeof OrgInvitationKey>;

export const WorkspaceInvitationHandoffKey = pipe(
  S.UUID,
  S.brand("WorkspaceInvitationHandoffKey"),
  S.annotations({
    parseIssueTitle: () => "Invalid workspace invitation",
  }),
);
export type WorkspaceInvitationHandoffKey = S.Schema.Type<
  typeof WorkspaceInvitationHandoffKey
>;

/**
 * Represents a color in the Oklch color space.
 * Oklch is a perceptually uniform color space that provides better control over color properties.
 * - chroma: Represents the colorfulness or saturation (0-0.4 is a good range)
 * - hue: Represents the hue angle in degrees (0-360)
 */
export const Color = S.Struct({
  chroma: S.Number,
  hue: S.Number,
});
export type Color = S.Schema.Type<typeof Color>;

export class InternalError extends S.TaggedClass<InternalError>()(
  "InternalError",
  {
    /** the Sentry reported error's reference id (to be used to look up the error in Sentry) */
    referenceID: S.String,
    /** the Sentry environment */
    environment: S.String.pipe(S.optional),
    /** the deployment release */
    release: S.String.pipe(S.optional),
    /** The request ID, which is unique to each HTTP request */
    requestID: S.String,
  },
) {
  [HttpServerRespondable.symbol]() {
    const label = [this.environment, this.release, this.requestID]
      .filter(isTruthy)
      .join(" ");
    return HttpServerResponse.text(
      basicHTMLPage({
        title: "Internal Error",
        color: { hue: 10, chroma: 0.1 },
        bodyHTML: [
          html` <strong>Whoops! Something went wrong.</strong>
            <p>We're sorry, but something went wrong on our servers.</p>
            <p>
              Please report the following reference ID to our team:
              <code>${this.referenceID} (${label})</code>
            </p>`,
        ],
      }).HTML,
      { status: 500, contentType: "text/html" },
    );
  }
}

export class InsufficientPermissions extends S.TaggedClass<InsufficientPermissions>()(
  "InsufficientPermissions",
  { displayMessage: S.String, potentialSolution: S.String },
) {
  [HttpServerRespondable.symbol]() {
    return HttpServerResponse.text(
      basicHTMLPage({
        title: "Insufficient permissions",
        color: { hue: 10, chroma: 0.1 },
        bodyHTML: [
          html`
            <p style="white-space: pre-wrap">${this.displayMessage}</p>
            <p>${this.potentialSolution}</p>
          `,
        ],
      }).HTML,
      { status: 401, contentType: "text/html" },
    );
  }
}

export class BadRequestWhoops extends S.TaggedClass<BadRequestWhoops>()(
  "BadRequestWhoops",
  {
    whatWentWrong: S.String,
    potentialSolution: S.String,
  },
) {
  [HttpServerRespondable.symbol]() {
    return HttpServerResponse.text(
      basicHTMLPage({
        title: "Whoops! We weren't expecting that.",
        color: { hue: 10, chroma: 0.01 },
        bodyHTML: [
          html` <p style="white-space: pre-wrap">${this.whatWentWrong}</p>
            <p>${this.potentialSolution}</p>`,
        ],
      }).HTML,
      { status: 400, contentType: "text/html" },
    );
  }
}

/**
 * A collection of general UI errors for requests that have some follow up action
 * for the User to take such as reporting an internal error or understanding the issue.
 */
export const AppRequestFailures = S.Union(
  BadRequestWhoops,
  InsufficientPermissions,
  InternalError,
);
export type AppRequestFailures = S.Schema.Type<typeof AppRequestFailures>;

export const CommentTsID = pipe(S.DateFromNumber, S.brand("CommentID"));
export type CommentTsID = S.Schema.Type<typeof CommentTsID>;

export const GroceryListID = pipe(S.UUID, S.brand("GroceryListID"));
export type GroceryListID = S.Schema.Type<typeof GroceryListID>;

export const GroceryListItemID = pipe(S.UUID, S.brand("GroceryListItemID"));
export type GroceryListItemID = S.Schema.Type<typeof GroceryListItemID>;

export const GroceryListProposalID = pipe(
  S.UUID,
  S.brand("GroceryListProposalID"),
);
export type GroceryListProposalID = S.Schema.Type<typeof GroceryListProposalID>;

export const GroceryListChangeKind = S.Union(
  S.Struct({
    _tag: S.Literal("AddItem"),
    itemId: GroceryListItemID,
    title: S.String,
    price: S.Number.pipe(S.nonNegative()),
  }),
  S.Struct({
    _tag: S.Literal("RemoveItem"),
    itemId: GroceryListItemID,
  }),
  S.Struct({
    _tag: S.Literal("UpdateItemTitle"),
    itemId: GroceryListItemID,
    newTitle: S.String,
  }),
  S.Struct({
    _tag: S.Literal("UpdateItemPrice"),
    itemId: GroceryListItemID,
    newPrice: S.Number.pipe(S.nonNegative()),
  }),
  S.Struct({
    _tag: S.Literal("UpdateDisplayName"),
    newDisplayName: S.String,
  }),
  S.Struct({
    _tag: S.Literal("UpdateDescription"),
    newDescription: S.String,
  }),
  S.Struct({
    _tag: S.Literal("UpdateColor"),
    newColor: Color,
  }),
);
export type GroceryListChangeKind = S.Schema.Type<typeof GroceryListChangeKind>;

export const GroceryListChange = S.Struct({
  timestamp: S.Number,
  change: GroceryListChangeKind,
});
export type GroceryListChange = S.Schema.Type<typeof GroceryListChange>;

export const GroceryListChangeset = S.Struct({
  id: S.UUID,
  changes: S.Array(GroceryListChange),
  authoredBy: OrgUserID.pipe(S.optional),
  mergedBy: OrgUserID.pipe(S.optional),
  proposalId: GroceryListProposalID.pipe(S.optional),
  createdAt: S.DateFromNumber,
});
export type GroceryListChangeset = S.Schema.Type<typeof GroceryListChangeset>;

export const GroceryListInfo = S.Struct({
  id: GroceryListID,
  displayName: S.String,
  description: S.String,
  color: Color,
  changesets: S.Array(GroceryListChangeset).pipe(
    S.optional,
    S.withDefaults({
      constructor() {
        return [];
      },
      decoding() {
        return [];
      },
    }),
  ),
});
export type GroceryListInfo = S.Schema.Type<typeof GroceryListInfo>;

export const GroceryListCommentContentKind = S.Union(
  S.Struct({
    _tag: S.Literal("Markdown"),
    markdown: S.String,
  }),
  S.Struct({
    _tag: S.Literal("Changes"),
    changes: S.Array(GroceryListChangeKind),
  }),
);
export type GroceryListCommentContentKind = S.Schema.Type<
  typeof GroceryListCommentContentKind
>;

export const ProposalStatus = S.Literal(
  "draft",
  "open",
  "approved",
  "rejected",
  "merged",
).pipe(S.brand("ProposalStatus"));
export type ProposalStatus = S.Schema.Type<typeof ProposalStatus>;

export const CommentVisibility = S.Literal("visible", "hidden", "deleted").pipe(
  S.brand("CommentVisibility"),
);
export type CommentVisibility = S.Schema.Type<typeof CommentVisibility>;
