import { S } from "@phosphor/prelude";
import { AppRequestFailures, OrgFolderID, OrgID } from "../common"; // Add the missing 'from' statement

export class FolderInfo extends S.Class<FolderInfo>("FolderCreated")({
  org_id: OrgID,
  folder_id: OrgFolderID,
  folder_display_name: S.String,
}) {}

export class CreateFolder extends S.TaggedRequest<CreateFolder>()(
  "CreateFolder",
  {
    payload: { org: OrgID, displayName: S.String },
    failure: AppRequestFailures,
    success: FolderInfo,
  },
) {}

export class GetOrgFolders extends S.TaggedRequest<GetOrgFolders>()(
  "GetOrgFolders",
  {
    payload: { org: OrgID },
    success: S.Array(FolderInfo),
    failure: AppRequestFailures,
  },
) {}
