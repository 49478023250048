import { UserNameChip } from "@/components/UserNameChip";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useRpcQuery } from "@/hooks/use-rpc-hooks";
import { useCurrentOrg } from "@/routes/$org._layout";
import {
  GroceryListProposalAPI,
  GroceryListProposalID,
} from "@phosphor/server";
import React from "react";

interface GLProposalChipProps {
  proposalId: GroceryListProposalID;
}

export const GLProposalChip: React.FC<GLProposalChipProps> = ({
  proposalId,
}) => {
  const { org } = useCurrentOrg();

  const {
    data: proposalData,
    isLoading,
    error,
  } = useRpcQuery({
    queryKey: ["proposal-details", proposalId],
    orgID: org.id,
    request: new GroceryListProposalAPI.GetProposal({
      proposalId,
    }),
  });

  if (isLoading) {
    return (
      <span $="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs">
        Loading...
      </span>
    );
  }

  if (error || !proposalData) {
    return (
      <span $="inline-block bg-red-200 rounded-full px-2 py-1 text-xs">
        Unknown Proposal
      </span>
    );
  }

  const { proposal } = proposalData;

  const getStatusBadge = (status: typeof proposal.status) => {
    const statusColors = {
      draft: "bg-gray-200 text-gray-800",
      open: "bg-blue-200 text-blue-800",
      approved: "bg-green-200 text-green-800",
      rejected: "bg-red-200 text-red-800",
      merged: "bg-purple-200 text-purple-800",
    } as const;

    return (
      <Badge
        $={statusColors[status as keyof typeof statusColors]}
        variant="outline"
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Badge>
    );
  };

  return (
    <HoverCard>
      <HoverCardTrigger $>
        <span $="inline-flex items-center bg-purple-100 text-purple-800 rounded-full px-2 py-1 text-xs cursor-pointer">
          <Avatar $="w-4 h-4 mr-1">
            <AvatarFallback $>P</AvatarFallback>
          </Avatar>
          Proposal #{proposalId.slice(0, 8)}
        </span>
      </HoverCardTrigger>
      <HoverCardContent $="w-80">
        <div $="space-y-2">
          <h4 $="text-sm font-semibold">{proposal.title}</h4>
          <p $="text-xs text-gray-500">{proposal.description}</p>
          <div $="flex items-center space-x-2">
            <span $="text-xs text-gray-500">Status:</span>
            {getStatusBadge(proposal.status)}
          </div>
          <div $="flex items-center space-x-2">
            <span $="text-xs text-gray-500">Created by:</span>
            <UserNameChip userId={proposal.createdBy} />
          </div>
          <div $="flex items-center space-x-2">
            <span $="text-xs text-gray-500">Created at:</span>
            <span $="text-xs">
              {new Date(proposal.createdAt).toLocaleString()}
            </span>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
