import { useGetModel } from "@/hooks/useModels";
import { Button } from "./ui/button";
import { Ta } from "./ui/icons";

export const Dashboard = () => {
  const { data: model } = useGetModel();
  return (
    <div $="border border-[#D4D4D8] rounded-sm">
      <div $="flex justify-between w-100 h-[48px] items-center  border-b-2 border-b-[#D4D4D8]">
        <text $="text-[#202734] text-[14px] font-medium ml-2">DASHBOARD</text>
        <div $="flex justify-between items-center">
          <Button $="border  bg-background text-black border-[#D4D4D8] rounded-full w-[100px] h-[22px] text-[12px] hover:bg-background">
            Equity metrics
          </Button>
          <Button $="border  bg-background text-black border-[#D4D4D8] rounded-full w-[100px] h-[22px] text-[12px] ml-2 hover:bg-background">
            Evaluation
          </Button>
          <Button $="border  bg-background text-black border-[#D4D4D8] rounded-full w-[100px] h-[22px] text-[12px] ml-2 hover:bg-background">
            Credit metrics
          </Button>
          <Ta.IconPlus $="w-[20px] h-[20px] ml-1" color={"#7487AA"} />
        </div>
        <Ta.IconWindowMaximize $="w-[20px] h-[20px] mr-2" color={"#7487AA"} />
      </div>
      <div $="bg-background text-black">
        <table $="min-w-full bg-background ">
          <thead $>
            <tr $>
              <th colSpan={2} $="px-4 py-2"></th>
              <th $="px-4 py-2 text-center text-black font-semibold text-[12px]">
                PPA 20yr
              </th>
              <th $="px-4 py-2 text-center text-black font-semibold text-[12px]">
                Project life
              </th>
            </tr>
          </thead>
          <tbody $="">
            <tr $="">
              <td colSpan={2} $="px-4 py-2 font-bold text-left text-[12px]">
                {model?.name}
              </td>
              <td $="" colSpan={2}></td>
            </tr>
            {model?.metrics.map((metric) => (
              <tr $="">
                <td colSpan={2} $="px-4 py-2 text-left text-[12px]">
                  {metric.label}
                </td>
                <td $="px-4 py-2 text-center text-[12px]">
                  {metric.values["PPA 20yr"]}
                </td>
                <td $="px-4 py-2 text-center text-[12px]">
                  {metric.values["Project life"]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div $="border-t border-black my-4"></div>

        <table $="min-w-full bg-background">
          <thead $="">
            <tr $="">
              <th colSpan={4} $="px-4 py-2 text-[12px]"></th>
              <th $="px-4 py-2 text-center text-[12px]">%</th>
            </tr>
          </thead>
          <tbody $="">
            <tr $="">
              <td colSpan={2} $="px-4 py-2 font-bold text-left text-[12px]">
                {model?.name}
              </td>
              <td $="" colSpan={2}></td>
            </tr>
            {model?.metrics.map((metric) => (
              <tr $>
                <td colSpan={2} $="px-4 py-2 text-left pb-5 text-[12px]">
                  {metric.label}
                </td>
                <td $="px-4 py-2 text-center pb-5 text-[12px]">
                  {metric.values["PPA 20yr"]}
                </td>
                <td $="px-4 py-2 text-center pb-5 text-[12px]">
                  {metric.values["Project life"]}
                </td>
                <td $="px-4 py-2 text-center pb-5 text-[12px]">
                  <div
                    $={`inline-flex items-center justify-center w-10 h-6 ${metric.values.percentage.startsWith("-") ? "bg-red-500" : "bg-green-500"} text-white rounded text-[12px]`}
                  >
                    {metric.values.percentage}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
